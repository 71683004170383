const pt = {
    translation: {
        //original: translated
        "Last 30 days": "Próximos 30 dias",
        "company logo": "logotipo da empresa",
        "You have x pending questions": "Você tem <span class='pending-number'>{{amount}}</span> perguntas pendentes",
        "You have x pending question": "Você tem <span class='pending-number'>{{amount}}</span> perguntas pendentes",
        "x pending question": "<span class='pending-number'>{{amount}}</span> perguntas pendentes",
        "x pending questions": "<span class='pending-number'>{{amount}}</span> perguntas pendentes",
        "Get my score": "Obter minha pontuação",
        "Time remaining...": "Tempo restante...",
        Score: "Score",
        "Peer Group Score": "Pontuação do Grupo de Pares",
        Engagement: "Envolvimento",
        "Excellence Score": "Pontuação de Excelência",
        "Your Score (This Month)": "Sua pontuação (este mês)",
        "Your Engagement (This Month)": "Seu Envolvimento (este mês)",
        "Your Excellence Score (This Month)": "Sua Pontuação de Excelência (este mês)",
        "Your Knowledge Score (last 3 months)": "Sua Pontuação de Conhecimento (últimos 3 meses)",
        "Your Knowledge Score (last 3 months) Detail": "Sua Pontuação de Conhecimento (últimos 3 meses) Detalhe",
        "Your Performance (rolling 6 months)": "Seu Desempenho (6 meses contínuos)",
        "Your Question Answer Rate (Engagement)": "Sua Taxa de Resposta a Perguntas (Envolvimento)",
        Low: "Baixo",
        High: "Alta",
        Performance: "Desempenho",
        "Peer Group": "Grupo de Pares",
        "High Performance Zone": "Zona de Alto Desempenho",
        "Invalid Question": "Pergunta Inválida",
        "Invalid link": "Link inválido",
        "Invalid Tracking Code": "Código de Rastreamento Inválido",
        "Engagement Rate": "Taxa de Envolvimento",
        "Competency Score": "Pontuação de Competência",
        "Volume Of Questions Answered": "Volume de Perguntas Respondidas",
        "Click here to answer your question": "Clique aqui para responder à sua pergunta",
        "question illustration": "ilustração de perguntas",
        Answered: "Respondido",
        Timeout: "Tempo limite",
        "Get My Score!": "Obter Minha Pontuação!",
        Correct: "Correto",
        Incorrect: "Incorreto",
        "x% of others answered this question correctly over the last 3 months.":
            "<span class='donut-chart-percentage'>{{amount}}%</span> das outras pessoas responderam a esta pergunta corretamente nos últimos 3 meses.",
        "Click on the related bar to visit associated training material if available":
            "Clique na barra relacionada para visitar o material de treinamento associado, se disponível",
        Submit: "Enviar",
        "Your Compliance Score (rolling 6 months)": "Sua Pontuação de Conformidade (6 meses contínuos)",
        title: {
            infoBanner: {
                engagementRate: "Taxa de Envolvimento",
                competencyScore: "Pontuação de Competência",
                excellenceScore: "Pontuação de Excelência",
                volumeOfQuestionsAnswered: "Volume de Perguntas Respondidas",
            },
        },
        tooltip: {
            infoBanner: {
                engagementRate: "Proporção de perguntas respondidas com base na data de envio.",
                competencyScore: "Proporção de perguntas respondidas corretamente com base na data da resposta.",
                excellenceScore: "Pontuação de envolvimento vezes Pontuação de competência.",
            },
            chart: {
                yourScoreThisMonth: "Proporção de respostas corretas a partir do dia 1º deste mês e pontuação média de seus pares.",
                yourEngagementThisMonth: "Proporção de perguntas respondidas que enviamos a você este mês.",
                yourExcellenceScoreThisMonth: "Pontuação de envolvimento vezes Pontuação de competência.",
                yourKnowledgeScoreLast3Months: "A pontuação de competência por categoria foi classificada da mais alta para a mais baixa.",
                yourComplianceScoreRolling6Months:
                    "Você pontua com base na última intervenção com cada pergunta nos 12 meses anteriores. Se você levar 3 tentativas para acertar uma pergunta, a última resposta foi uma resposta correta e, portanto, pontua 100%.",
            },
        },
        "We were not able to connect to the server, please retry the question link tomorrow.":
            "Não foi possível conectar-se ao servidor. Tente novamente o link da pergunta amanhã.",
        "question video": "vídeo de perguntas",
        errorMessages: {
            questionExpired: "O link da pergunta expirou",
            questionOpened: "A pergunta já está aberta",
            questionRemovedByAdmin: "Essa pergunta foi removida pelo administrador.",
            questionAccess: "Falha no acesso à pergunta",
            fileAccess: "Falha no acesso ao arquivo",
            internalServer: "Ocorreu um erro interno do servidor",
            invalidRequest: "Solicitação Inválida",
        },
        "We were not able to connect to the server. Refreshing in": "Não conseguimos nos conectar ao servidor. Atualizando em {{leftSecond}}",
        "CONGRATULATIONS": "PARABÉNS",
        "Thank you for your contribution": "Obrigado pela sua contribuição",
        notification: {
            openNotifications: "Ver notificações",
            noData: "Você não tem notificações desde o mês passado",
            exportFileName: "Notificações",
            header: "Notificações",
            columns: {
                notificationTime: "Hora da Notificação",
                explanation: "Mensagem"

            }
        }

    },
};

export default pt;
