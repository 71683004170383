const es = {
    translation: {
        //original: translated
        "Last 30 days": "Últimos 30 días",
        "company logo": "logotipo de la empresa",
        "You have x pending questions": "Tiene <span class='pending-number'>{{amount}}</span> preguntas pendientes",
        "You have x pending question": "Tiene <span class='pending-number'>{{amount}}</span> preguntas pendientes",
        "x pending question": "<span class='pending-number'>{{amount}}</span> preguntas pendientes",
        "x pending questions": "<span class='pending-number'>{{amount}}</span> preguntas pendientes",
        "Get my score": "Obtener la puntuación",
        "Time remaining...": "Tiempo restante...",
        Score: "Puntuación",
        "Peer Group Score": "Puntuación del grupo de compañeros",
        Engagement: "Participación",
        "Excellence Score": "Puntuación de excelencia",
        "Your Score (This Month)": "Su puntuación (este mes)",
        "Your Engagement (This Month)": "Su participación (este mes)",
        "Your Excellence Score (This Month)": "Su puntuación de excelencia (este mes)",
        "Your Knowledge Score (last 3 months)": "Su puntuación de conocimiento (últimos 3 meses)",
        "Your Knowledge Score (last 3 months) Detail": "Detalles de su puntuación de conocimiento (últimos 3 meses)",
        "Your Performance (rolling 6 months)": "Su rendimiento (6 meses consecutivos)",
        "Your Question Answer Rate (Engagement)": "Su tasa de respuesta a preguntas (participación)",
        Low: "Bajo",
        High: "Alto",
        Performance: "Rendimiento",
        "Peer Group": "Grupo de compañeros",
        "High Performance Zone": "Zona de alto rendimiento",
        "Invalid Question": "Pregunta no válida",
        "Invalid link": "Enlace no válido",
        "Invalid Tracking Code": "Código de seguimiento no válido",
        "Engagement Rate": "Tasa de participación",
        "Competency Score": "Puntuación de competencia",
        "Volume Of Questions Answered": "Volumen de preguntas respondidas",
        "Click here to answer your question": "Haga clic aquí para responder a su pregunta",
        "question illustration": "ilustración de pregunta",
        Answered: "Respondida",
        Timeout: "Tiempo de espera",
        "Get My Score!": "Obtener mi puntuación",
        Correct: "Correcto",
        Incorrect: "Incorrecto",
        "x% of others answered this question correctly over the last 3 months.":
            "El <span class='donut-chart-percentage'>{{amount}}%</span> de los demás respondieron correctamente a esta pregunta en los últimos 3 meses.",
        "Click on the related bar to visit associated training material if available":
            "Si está disponible, haga clic en la barra correspondiente para visitar el material de formación asociado.",
        Submit: "Enviar",
        "Your Compliance Score (rolling 6 months)": "Su puntuación de cumplimiento (6 meses consecutivos)",
        title: {
            infoBanner: {
                engagementRate: "Tasa de participación",
                competencyScore: "Puntuación de competencia",
                excellenceScore: "Puntuación de excelencia",
                volumeOfQuestionsAnswered: "Volumen de preguntas respondidas",
            },
        },
        tooltip: {
            infoBanner: {
                engagementRate: "Proporción de preguntas respondidas en función de la fecha de envío.",
                competencyScore: "Proporción de preguntas respondidas correctamente en función de la fecha de respuesta.",
                excellenceScore: "Puntuación de participación multiplicada por la puntuación de competencia.",
            },
            chart: {
                yourScoreThisMonth: "Proporción de respuestas correctas desde el día 1 de este mes y puntuación media de sus compañeros.",
                yourEngagementThisMonth: "Proporción de preguntas respondidas que le enviamos este mes.",
                yourExcellenceScoreThisMonth: "Puntuación de participación multiplicada por la puntuación de competencia.",
                yourKnowledgeScoreLast3Months: "Puntuación de competencia por categoría clasificada de mayor a menor.",
                yourComplianceScoreRolling6Months:
                    "Su puntuación se basa en la última intervención con cada pregunta durante los 12 meses anteriores. Si le cuesta 3 intentos responder una pregunta correctamente, la última respuesta se considera correcta y, por lo tanto, obtiene una puntuación del 100 %.",
            },
        },
        "We were not able to connect to the server, please retry the question link tomorrow.":
            "No hemos podido conectarnos al servidor. Vuelva a intentar acceder con el enlace de la pregunta mañana.",
        "question video": "vídeo de preguntas",
        errorMessages: {
            questionExpired: "Enlace de pregunta caducado",
            questionOpened: "La pregunta ya está abierta",
            questionRemovedByAdmin: "El administrador ha eliminado esta pregunta",
            questionAccess: "Error al acceder a la pregunta",
            fileAccess: "Error al acceder al archivo",
            internalServer: "Error interno inesperado del servidor",
            invalidRequest: "Solicitud no válida",
        },
        "We were not able to connect to the server. Refreshing in": "No hemos podido conectarnos al servidor. Actualizando en {{leftSecond}}",
        "CONGRATULATIONS": "FELICITACIONES",
        "Thank you for your contribution": "Gracias por tu contribución",
        notification: {
            openNotifications: "Ver notificaciones",
            noData: "No tienes notificaciones desde el mes pasado",
            exportFileName: "Notificaciones",
            header: "Notificaciones",
            columns: {
                notificationTime: "Hora de la Notificación",
                explanation: "Mensaje"
            }
        }
    },
};

export default es;
