import { Navigate, Route, Routes } from "react-router-dom";
import DashboardQA from "../base/DashboardQA";
import PerformanceQA from "../performance/PerformanceQA";
import { useContext } from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import PreQuestion from "../common/questionAnswer/PreQuestion";
import QAInfo from "../common/info/QAInfo";
import useAuth from "../../functions/auth";
const Routing = ({ company }) => {
    const { token, loginInProgress, error, tokenData } = useContext(AuthContext);
    const { isSso } = useAuth();

    if (loginInProgress || error) {
        return null;
    }

    const nowTime = new Date().getTime();

    if (!isSso || (token && tokenData && tokenData.exp * 1000 > nowTime)) {
        return (
            <Routes>
                {!!tokenData?.state && (
                    <Route path={`/sso${company ? "-" + company : ""}/authorized`} element={<Navigate to={`/sso${company ? "-" + company : ""}/question?${tokenData.state}`} />} />
                )}
                <Route element={<DashboardQA />}>
                    <Route path="/question" element={<PreQuestion />} />
                    <Route path={`/sso${company ? "-" + company : ""}/question`} element={<PreQuestion />} />
                    <Route path="/answer" element={<PreQuestion />} />
                    <Route path={`/sso${company ? "-" + company : ""}/answer`} element={<PreQuestion />} />
                    <Route path="/performance" element={<PerformanceQA />} />
                    <Route path={`/sso${company ? "-" + company : ""}/performance`} element={<PerformanceQA />} />
                    <Route path="/info" element={<QAInfo/>}/>
                    <Route path={`/sso${company ? "-" + company : ""}/info`} element={<QAInfo/>}/>
                </Route>
                <Route path="/mobile/performance" element={<PerformanceQA />} />
            </Routes>
        );
    }
};
export default Routing;
