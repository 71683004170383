const tr = {
    translation: {
        //original: translated
        "Last 30 days": "Son 30 gün",
        "company logo": "şirket logosu",
        "You have x pending questions": "Bekleyen <span class='pending-number'>{{amount}}</span> soru var",
        "You have x pending question": "Bekleyen <span class='pending-number'>{{amount}}</span> soru var",
        "x pending question": "<span class='pending-number'>{{amount}}</span> bekleyen soru",
        "x pending questions": "<span class='pending-number'>{{amount}}</span> bekleyen soru",
        "Get my score": "Skorumu göster",
        "Time remaining...": "Kalan zaman...",
        Score: "Skor",
        "Peer Group Score": "Grup Skoru",
        Engagement: "Katılım",
        "Excellence Score": "Mükemmellik Skoru",
        "Your Score (This Month)": "Skorun (Bu Ay)",
        "Your Engagement (This Month)": "Katılımın (Bu Ay)",
        "Your Excellence Score (This Month)": "Mükemmelik Skorun (Bu Ay)",
        "Your Knowledge Score (last 3 months)": "Bilgi Skorun (son 3 ay)",
        "Your Knowledge Score (last 3 months) Detail": "Bilgi Skorun (son 3 ay) Detay",
        "Your Performance (rolling 6 months)": "Performansın (yuvarlanan 6 ay)",
        "Your Question Answer Rate (Engagement)": "Soru Cevaplama Oranın (Katılım)",
        Low: "Düşük",
        High: "Yüksek",
        Performance: "Performans",
        "Peer Group": "Grup",
        "High Performance Zone": "Yüksek performans bölgesi",
        "Invalid Question": "Geçersiz soru",
        "Invalid link": "Geçersiz link",
        "Invalid Tracking Code": "Geçersiz soru kodu",
        "Engagement Rate": "Katılım oranı",
        "Competency Score": "Yeterlilik skoru",
        "Volume Of Questions Answered": "Cevaplanan Soru Miktarı",
        "Click here to answer your question": "Soruyu cevaplamak için buraya tıklayın",
        "question illustration": "question resmi",
        Answered: "Cevaplandı",
        Timeout: "Zaman aşımı",
        "Get My Score!": "Skorumu göster!",
        Correct: "Doğru",
        Incorrect: "Yanlış",
        "x% of others answered this question correctly over the last 3 months.":
            "Son 3 ayda diğerlerinin <span class='donut-chart-percentage'>%{{amount}}</span> kadarı bu soruyu doğru cevapladı",
        "Click on the related bar to visit associated training material if available": "Varsa ilgili eğitim materyalini ziyaret etmek için ilgili çubuğa tıklayın",
        Submit: "Onayla",
        "Thank you for your contribution": "Katkınız için teşekkürler",
        notification: {
            openNotifications: "Bildirimleri gör",
            noData: "Geçen aydan bu yana bildiriminiz yok",
            exportFileName: "Bildirimler",
            header: "Bildirimler",
            columns: {
                notificationTime: "Bildirim Zamanı",
                explanation: "Mesaj"
            }
        }
    },
};

export default tr;
