const ko = {
    translation: {
        //original: translated
        "Last 30 days": "지난 30일",
        "company logo": "회사 로고",
        "You have x pending questions": "내 대기 중인 질문 <span class='pending-number'>{{amount}}</span>개",
        "You have x pending question": "내 대기 중인 질문 <span class='pending-number'>{{amount}}</span>개",
        "x pending question": "대기 중인 질문 <span class='pending-number'>{{amount}}</span>개",
        "x pending questions": "대기 중인 질문 <span class='pending-number'>{{amount}}</span>개",
        "Get my score": "내 점수 받기",
        "Time remaining...": "남은 시간...",
        Score: "점수",
        "Peer Group Score": "동료 그룹 점수",
        Engagement: "참여도",
        "Excellence Score": "우수성 점수",
        "Your Score (This Month)": "내 점수(이번 달)",
        "Your Engagement (This Month)": "내 참여도(이번 달)",
        "Your Excellence Score (This Month)": "내 우수성 점수(이번 달)",
        "Your Knowledge Score (last 3 months)": "내 지식 점수(최근 3개월)",
        "Your Knowledge Score (last 3 months) Detail": "내 지식 점수(최근 3개월) 세부 정보",
        "Your Performance (rolling 6 months)": "내 성과(6개월 내)",
        "Your Question Answer Rate (Engagement)": "내 질문 답변 비율(참여도)",
        Low: "낮음(영문)",
        High: "높음",
        Performance: "성능",
        "Peer Group": "동료 그룹",
        "High Performance Zone": "고성과 영역",
        "Invalid Question": "유효하지 않은 질문",
        "Invalid link": "유효하지 않은 링크",
        "Invalid Tracking Code": "유효하지 않은 추적 코드",
        "Engagement Rate": "참여율",
        "Competency Score": "역량 점수",
        "Volume Of Questions Answered": "답변한 질문 수",
        "Click here to answer your question": "여기를 클릭하여 내 질문에 답변",
        "question illustration": "질문 일러스트",
        Answered: "답변 완료",
        Timeout: "시간 초과",
        "Get My Score!": "내 점수 받기!",
        Correct: "정답",
        Incorrect: "오답",
        "x% of others answered this question correctly over the last 3 months.":
            "최근 3개월 동안 다른 사람들 중 <span class='donut-chart-percentage'>{{amount}}%</span>가 이 질문에 올바르게 답변했습니다.",
        "Click on the related bar to visit associated training material if available": "관련 교육 자료가 있는 경우 방문하려면 관련 바를 클릭하세요",
        Submit: "제출",
        "Your Compliance Score (rolling 6 months)": "규정 준수 점수(6개월 내)",
        title: {
            infoBanner: {
                engagementRate: "참여율",
                competencyScore: "역량 점수",
                excellenceScore: "우수성 점수",
                volumeOfQuestionsAnswered: "답변한 질문 수",
            },
        },
        tooltip: {
            infoBanner: {
                engagementRate: "보낸 날짜를 기준으로 답변한 질문의 비율입니다.",
                competencyScore: "답변 날짜를 기준으로 올바르게 답변한 질문의 비율입니다.",
                excellenceScore: "참여도 점수에 역량 점수를 곱한 것입니다.",
            },
            chart: {
                yourScoreThisMonth: "이달 1일부터 지금까지 정답 비율과 동료의 평균 점수입니다.",
                yourEngagementThisMonth: "이번 달에 보내 드린 질문에 답변한 비율입니다.",
                yourExcellenceScoreThisMonth: "참여도 점수에 역량 점수를 곱한 것입니다.",
                yourKnowledgeScoreLast3Months: "내림차순으로 표시한 카테고리별 역량 점수입니다.",
                yourComplianceScoreRolling6Months:
                    "지난 12개월 동안 각 질문에 대한 마지막 반응을 기반으로 매긴 내 점수입니다. 정답을 맞히기 위해 3번 시도한 경우에도 마지막 답이 정답이라면 100% 점수를 얻습니다.",
            },
        },
        "We were not able to connect to the server, please retry the question link tomorrow.": "서버에 연결할 수 없습니다. 내일 질문 링크 사용을 다시 시도해 주세요.",
        "question video": "질문 비디오",
        errorMessages: {
            questionExpired: "질문 링크 만료됨",
            questionOpened: "질문이 이미 열려 있음",
            questionRemovedByAdmin: "관리자가 제거한 질문",
            questionAccess: "질문 액세스 실패",
            fileAccess: "파일 액세스 실패",
            internalServer: "예기치 못한 내부 서버 오류 발생",
            invalidRequest: "유효하지 않은 요청",
        },
        "We were not able to connect to the server. Refreshing in": "서버에 연결하지 못했습니다. {{leftSecond}} 후 새로 고침",
        "CONGRATULATIONS": "축하합니다",
        "Thank you for your contribution": "당신의 기여에 감사드립니다",
        notification: {
            openNotifications: "알림 확인",
            noData: "지난 달 이후로 알림이 없습니다",
            exportFileName: "알림",
            header: "알림",
            columns: {
                notificationTime: "알림 시간",
                explanation: "메시지"
            }
        }

    },
};

export default ko;
