import * as am5 from "@amcharts/amcharts5";

export class LineChartColorTheme extends am5.Theme {
    setupDefaultRules() {
        super.setupDefaultRules();

        this.rule("ColorSet").set("colors", [
            am5.color("#6736F4"),
            am5.color("#9A7CEF"),
            am5.color("#CCBDF6"),
            am5.Color.lighten(am5.color("#CCBDF6"), 0.2),
            am5.Color.lighten(am5.color("#CCBDF6"), 0.4),
        ]);
    }
}
