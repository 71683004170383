export const DRAWER_WIDTH = 300;
export const SUPPORTED_LANGUAGES = ["en", "tr", "fr", "ch", "es", "ja", "ko", "pt", "frca"];

//STATUS
export const QUESTION_ANSWERED = "QUESTION_ANSWERED";
export const QUESTION_NEW = "QUESTION_SENT";
export const QUESTION_LOADED = "QUESTION_LOADED"; //come from server
export const WINDOW_CLOSED = "WINDOW_CLOSED";
export const QUESTION_TIMEOUT = "TIMEOUT";
export const QUESTION_ANSWERED_TEXT = "Answered";
export const QUESTION_TIMEOUT_TEXT = "Timeout";

export const STYLE_WELLBEING = "wellbeing"
