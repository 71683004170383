import { SvgIcon } from "@mui/material";

const ThumbsUpIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 28 25" {...props}>
            <path
                d="M10 25H21.25C22.2875 25 23.175 24.375 23.55 23.475L27.325 14.6625C27.4375 14.375 27.5 14.075 27.5 13.75V11.25C27.5 9.875 26.375 8.75 25 8.75H17.1125L18.3 3.0375L18.3375 2.6375C18.3375 2.125 18.125 1.65 17.7875 1.3125L16.4625 0L8.225 8.2375C7.775 8.6875 7.5 9.3125 7.5 10V22.5C7.5 23.875 8.625 25 10 25ZM10 10L15.425 4.575L13.75 11.25H25V13.75L21.25 22.5H10V10ZM0 10H5V25H0V10Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ThumbsUpIcon;
