import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import "./QuestionAnswer.sass";

export const QuestionNextActionButton = ({ t, customStyle, buttonAction }) => {
    return (
        <Button
            variant={customStyle}
            sx={(theme) => {
                return {
                    display: "flex",
                    width: "90%"
                };
            }}
            onClick={() => buttonAction()}
            fullWidth={false}
            className={`nextActionButton  ${customStyle}`}
            data-testid={"Open Next Question"}
            tabIndex={40}
        >
            <span>{t("Open Next Question")}</span>
            <ArrowForward fontSize="inherit" />
        </Button>)
}
