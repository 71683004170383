import { Box, IconButton, Modal, Tooltip } from "@mui/material";
import LauralBadge from "./LauralBadge";
import CloseIcon from "@mui/icons-material/Close";
import { withTranslation } from "react-i18next";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "95%",
    maxHeight: "95vh",
    transform: "translate(-50%, -50%)",
    border: "none",
    boxShadow: 0,
    background: "white",
};

const LauralBadgeModal = ({ t, lauralBadgeModalOpen, setLauralBadgeModalOpen }) => {
    return (
        <Modal open={lauralBadgeModalOpen} onClose={() => setLauralBadgeModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        zIndex: "1",
                        padding: "5px",
                    }}
                >
                    <Tooltip title={t("tooltip.laural.footer.narrative")} tabIndex={0} placement="right" arrow>
                        <HelpOutlineRoundedIcon tabIndex={0} aria-hidden={false} aria-label={t("tooltip.laural.footer.narrative")} className="tooltipIcon" />
                    </Tooltip>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        zIndex: "1",
                        padding: "0 4px",
                    }}
                >
                    <IconButton aria-label="close" onClick={() => setLauralBadgeModalOpen(false)} data-testid="closeBtn">
                        <CloseIcon style={{ fontSize: 14 }} />
                    </IconButton>
                </Box>
                <div
                    style={{
                        textAlign: "center",
                        backgroundColor: "#fff",
                    }}
                >
                    <LauralBadge />
                </div>
            </Box>
        </Modal>
    );
};

export default withTranslation()(LauralBadgeModal);
