import {Card, CardContent, CardHeader, Grid, Tooltip} from "@mui/material";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import "./ChartLayout.sass";

const ChartLayout = ({children, title, additionalHeight = 0, helpToolTip}) => {
    return (
        <Card>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <CardHeader tabIndex={0} subheader={title} subheaderTypographyProps={{height: 25}}/>
                {!!helpToolTip && (
                    <Tooltip title={helpToolTip} arrow placement="top">
                        <HelpOutlineRoundedIcon
                            tabIndex={0}
                            aria-hidden={false}
                            aria-label={helpToolTip}
                            className="chartTooltipIcon"
                            type="tooltip"
                        />
                    </Tooltip>
                )}
            </div>
            <CardContent>
                <Grid item xs={12} height={300 + additionalHeight}>
                    {children}
                </Grid>
            </CardContent>
        </Card>
    );
};
export default ChartLayout;
