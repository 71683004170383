import { SvgIcon } from "@mui/material";

const SurveyIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 24"  {...props}>
            <path
                d="M12.1,15H5V5h12v5.1c0.2,0,0.3-0.1,0.5-0.1c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0c0.2,0,0.3,0,0.5,0.1V5
        c0-0.5-0.2-1-0.6-1.4C18,3.2,17.5,3,17,3H5C4.5,3,4,3.2,3.6,3.6C3.2,4,3,4.4,3,5v15l3-3h6.1c0-0.2-0.1-0.3-0.1-0.5
        c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5C12,15.3,12,15.2,12.1,15z"
                fill="#6736F4="
            />
            <rect x="7" y="7" fill="#6736F4=" width="8" height="2" />
            <rect x="7" y="11" fill="#6736F4=" width="5" height="2" />
            <polygon
                fill="#5CCC85"
                points="19,15 19,12 17,12 17,15 14,15 14,17 17,17 17,20 19,20 19,17 22,17 22,15"
            />
        </SvgIcon>
    );
};

export default SurveyIcon;

