import { Box } from "@mui/material";

const Main = ({ children }) => {
    return (
        <Box
            component="main"
            sx={(theme) => {
                return {
                    display: "flex",
                    flexDirection: "column",
                    flex: "1 1 100%",
                    overflow: "hidden",
                    backgroundColor: theme.palette.background,
                };
            }}
        >
            {children}
        </Box>
    );
};

export default Main;
