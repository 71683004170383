import ChartLayout from "../charts/ChartLayout";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import LineChart from "../charts/LineChart";
import { useSelector } from "react-redux";

const ComplianceScore = ({ t, complianceScoreData }) => {
    const colorThemeMui = useSelector((state) => state.theme.colorThemeMui);
    useEffect(() => {
        let { root, chart } = { root: undefined, chart: undefined };

        const chartDiv = document.getElementById("byComplianceScore_chartDiv");
        if (!!complianceScoreData && !!chartDiv) {
            ({ root, chart } = LineChart({
                data: complianceScoreData,
                chartId: "byComplianceScore_chartDiv",
                colorThemeMui: colorThemeMui,
            }));
        }
        return () => {
            if (root) {
                root.dispose();
            }
        };
    }, [complianceScoreData]);

    return (
        <ChartLayout
            title={t("Your Compliance Score (rolling 6 months)")}
            helpToolTip={t("tooltip.chart.yourComplianceScoreRolling6Months")}
            additionalHeight={complianceScoreData?.length > 6 ? (complianceScoreData?.length - 6) * 30 : 0}
        >
            <div id="byComplianceScore_chartDiv" data-testid="chart-testid" style={{ width: "100%", height: "100%" }}></div>
        </ChartLayout>
    );
};

export default withTranslation()(ComplianceScore);
