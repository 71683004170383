import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from "react-oauth2-code-pkce";

const useAuth = () => {
    const location = useLocation();
    const { token } = useContext(AuthContext);
    const isSso = location.pathname.startsWith("/sso");
    const company = isSso && location.pathname.split("/")[1]?.split("-")[1];
    const authorization = isSso ? "Bearer " + token : undefined;

    return { isSso, company, authorization };
}

export default useAuth;
