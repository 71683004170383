import "./Note.sass";
import { InfoIcon } from "../../icons/InfoIcon";
import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "react-oauth2-code-pkce";
import { useSelector } from "react-redux";

const Note = ({ note, uniqueId, customStyle, disableInfoIcon, notelinkClicked }) => {

    const { token } = useContext(AuthContext);
    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    const location = useLocation();
    const isSso = location.pathname.startsWith("/sso");

    useEffect(() => {
        if (document.getElementById(`current_note_element-${uniqueId}`)?.getElementsByTagName("a")) {
            let links = document.getElementById(`current_note_element-${uniqueId}`)?.getElementsByTagName("a");
            Object.entries(links).forEach((el) => {
                let link = el[1];
                let url = link?.getAttribute("href");
                if (url) {
                    if (!url?.startsWith("http")) {
                        url = "https://" + url; // TODO link validation should be handled backend-side or plato
                    }
                    link.addEventListener("click", (event) => {
                        event.preventDefault();
                        window.open(url, '_blank');
                        notelinkClicked(url, isSso, token, trackingCode);
                    });
                }
            });
        }

    }, [note]);

    return (
        note?.length > 0 && (
            <div className={`note  ${customStyle}`} id={`current_note_element-${uniqueId}`} data-testid={`note${uniqueId}`}>
                {!disableInfoIcon && <InfoIcon uniqueId={uniqueId} />}
                <p tabIndex={39} dangerouslySetInnerHTML={{ __html: note }}></p>
            </div>
        )
    );
};

export default Note;
