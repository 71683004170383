import React, { useContext, useState } from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { withTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { DataTableContext } from "./DataTableContext";
import "./DataTable.sass";
import {
    GridToolbarContainer
} from "@mui/x-data-grid-pro";


const DataTable = (props) => {
    const { headers, data, displayStateProps, setSortAndPaginationProps, onRowClick,
        totalRecords, exportFileName = "", clientSidePagination = true, ToolbarComponent = undefined, getRowId,
        isAutoHeightActive = false
    } = props
    const theme = useTheme();
    const rows = data;
    const columns = headers;
    const [sortModel, setSortModel] = useState(displayStateProps?.sortModel);

    const onPageChange = (selectedPage, pinnedModel, sortModel) => {
        let newSortAndPaginationProps = { ...displayStateProps };
        newSortAndPaginationProps.pageNumber = selectedPage + 1;
        newSortAndPaginationProps.pinnedModel = pinnedModel;
        newSortAndPaginationProps.sortModel = sortModel;
        setSortAndPaginationProps(newSortAndPaginationProps);
    };
    const onPageSizeChange = (pageSize, pinnedModel, sortModel) => {
        let newSortAndPaginationProps = { ...displayStateProps };
        newSortAndPaginationProps.recordsPerPage = pageSize;
        newSortAndPaginationProps.pinnedModel = pinnedModel;
        newSortAndPaginationProps.sortModel = sortModel;
        setSortAndPaginationProps(newSortAndPaginationProps);
    };
    const onSortChange = (pinnedModel, sortModel) => {
        let newSortAndPaginationProps = { ...displayStateProps };
        newSortAndPaginationProps.sortCol = sortModel[0].field;
        newSortAndPaginationProps.sortDirection = sortModel[0].sort;
        newSortAndPaginationProps.pinnedModel = pinnedModel;
        newSortAndPaginationProps.sortModel = sortModel;
        setSortAndPaginationProps(newSortAndPaginationProps);
    };

    const onSort = (psortModel) => {
        if (psortModel.length === 0) {
            return;
        }
        setSortModel(psortModel);
        !clientSidePagination && onSortChange(pinnedModel, psortModel);
    };

    const [pinnedModel, setPinnedModel] = useState(displayStateProps?.pinnedModel);
    const onPinColumn = (pinnedColumnModel) => {
        setPinnedModel(pinnedColumnModel);
    };

    const [page, setPage] = useState(displayStateProps?.pageNumber - 1);

    const handlePageChange = (page) => {
        setPage(page);
        !clientSidePagination && onPageChange(page, pinnedModel, sortModel);
    };

    const [pageSize, setPageSize] = useState(displayStateProps?.recordsPerPage || 10);
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        !clientSidePagination && onPageSizeChange(size, pinnedModel, sortModel);
    };
    const handleClick = (param) => {
        !!onRowClick && onRowClick(param?.row);
    };

    const options = {
        // exportCsv: true,
        showTooltip: true,
        hover: true,
        filter: false,
        reaize: true,
    };

    const dataGridRef = useGridApiRef();
    const dataTableContext = useContext(DataTableContext);

    if (dataTableContext) {
        const { setDataTableApiRef, setFileName } = dataTableContext;
        if (setDataTableApiRef)
            setDataTableApiRef(dataGridRef);
        if (setFileName)
            setFileName(exportFileName);
    }


    const CustomToolbar = () => {
        return (
            ToolbarComponent ?
                <GridToolbarContainer>
                    <ToolbarComponent />
                </GridToolbarContainer>
                : <></>
        )
    };

    const rowStyles = isAutoHeightActive ? {
        '& .MuiDataGrid-cell': {
            padding: '12px',
        },
        '& .MuiDataGrid-footerContainer': {
            width: '100%',
            justifyContent: 'flex-start',
        },

    } : {};

    return (
        <Box sx={{ width: "100%", height: rows?.length <= 10 ? "100%" : "650px" }} data-testid="dataTable">
            <DataGridPro
                disableColumnFilter
                autoHeight={rows?.length <= 10}
                rows={rows}
                columns={columns}
                options={options}
                pinnedColumns={pinnedModel}
                onPinnedColumnsChange={onPinColumn}
                onRowClick={handleClick}
                theme={theme} // Add the custom theme
                pagination
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                page={page}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowCount={totalRecords}
                pageSize={pageSize}
                paginationMode={!clientSidePagination ? "server" : "client"}
                sortModel={sortModel}
                sortingMode={!clientSidePagination ? "server" : "client"}
                sortingOrder={["asc", "desc"]}
                onSortModelChange={onSort}
                columnResizingdatagridpro
                apiRef={dataGridRef}
                components={{
                    Toolbar: CustomToolbar,
                }}
                getRowId={!!getRowId && getRowId()}
                getRowHeight={isAutoHeightActive ? () => 'auto' : undefined}
                sx={{...rowStyles}}
            />
        </Box>
    );
};

export default withTranslation()(DataTable);
