import { createSlice } from "@reduxjs/toolkit";

const initState = [];
const DashboardChartSlice = createSlice({
    name: "dashboardChartInfo",
    initialState: initState,
    reducers: {
        setDashboardChart: (_state, { payload }) => {
            return payload;
        },
        reset: () => initState,
    },
});

export const { reset, setDashboardChart } = DashboardChartSlice.actions;
export default DashboardChartSlice.reducer;
