import { Box } from "@mui/material";
import "./InfoBanner.sass";
import InfoSection from "./InfoSection";
const InfoBanner = ({ info }) => {
    return (
        <Box
            data-testid="info-banner"
            id="info-banner"
            sx={{
                padding: "0 10px",
                marginBottom: "auto",
            }}
        >
            {!!info && info.map((el, idx) => <InfoSection key={el.title || idx} data={el} />)}
        </Box>
    );
};

export default InfoBanner;
