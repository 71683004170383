import { SvgIcon } from "@mui/material";

const ThumbsDownIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 28 25" {...props}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M10 0H21.25C22.2875 0 23.175 0.625 23.55 1.525L27.325 10.3375C27.4375 10.625 27.5 10.925 27.5 11.25V13.75C27.5 15.125 26.375 16.25 25 16.25H17.1125L18.3 21.9625L18.3375 22.3625C18.3375 22.875 18.125 23.35 17.7875 23.6875L16.4625 25L8.225 16.7625C7.775 16.3125 7.5 15.6875 7.5 15V2.5C7.5 1.125 8.625 0 10 0ZM10 15L15.425 20.425L13.75 13.75H25V11.25L21.25 2.5H10V15ZM0 15H5V0H0V15Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default ThumbsDownIcon;
