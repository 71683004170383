import * as am5 from "@amcharts/amcharts5";

export class HighContrastWhiteTheme extends am5.Theme {
    setupDefaultRules() {
        super.setupDefaultRules();

        this.rule("ColorSet").setAll({
            colors: [
                am5.color(0x000000), // Black
                am5.color(0xffffff), // White
            ],
            reuse: true,
            wrapping: false,
        });
        this.rule("Label").setAll({
            fill: am5.color(0x000000),
            fontSize: "1em",
        });
        this.rule("Grid").setAll({
            stroke: am5.color(0x000000),
            strokeWidth: 1,
            strokeOpacity: 1,
        });
    }
}
