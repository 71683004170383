import { SvgIcon } from "@mui/material";

const MenuOpenIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.20386 18H12.2039C12.7539 18 13.2039 17.55 13.2039 17C13.2039 16.45 12.7539 16 12.2039 16H4.20386C3.65386 16 3.20386 16.45 3.20386 17C3.20386 17.55 3.65386 18 4.20386 18ZM4.20386 13H15.2039C15.7539 13 16.2039 12.55 16.2039 12C16.2039 11.45 15.7539 11 15.2039 11H4.20386C3.65386 11 3.20386 11.45 3.20386 12C3.20386 12.55 3.65386 13 4.20386 13ZM3.20386 7C3.20386 7.55 3.65386 8 4.20386 8H12.2039C12.7539 8 13.2039 7.55 13.2039 7C13.2039 6.45 12.7539 6 12.2039 6H4.20386C3.65386 6 3.20386 6.45 3.20386 7ZM16.5039 14.88L19.3839 12L16.5039 9.12C16.1139 8.73 16.1139 8.1 16.5039 7.71C16.8939 7.32 17.5239 7.32 17.9139 7.71L21.5039 11.3C21.8939 11.69 21.8939 12.32 21.5039 12.71L17.9139 16.3C17.5239 16.69 16.8939 16.69 16.5039 16.3C16.1239 15.91 16.1139 15.27 16.5039 14.88Z" />
        </SvgIcon>
    );
};

export default MenuOpenIcon;
