
import { createSlice } from "@reduxjs/toolkit";
import { QUESTION_ANSWERED, QUESTION_TIMEOUT, QUESTION_NEW } from "../../../constants";
// import { linkedQuestions, wellbeingQuestion } from "../../../__tests__/TestData";


const initialState = {
    trackingCode: undefined,
    questionAnswerList: undefined,
    isLastQuestionInProgress: undefined, //last question status need tobe undefined to prevent 2 rendering of DashboardQA
    errorInfo: undefined
};

const QuestionAnswerSlice = createSlice({
    name: "questionAnswer",
    initialState,
    reducers: {
        setQuestionAnswerList: (state, { payload }) => {
            state.questionAnswerList = payload?.questionAndAnswerList;
            // state.questionAnswerList = wellbeingQuestion;
            state.trackingVisitId = payload?.trackingVisitId;
            state.errorInfo = payload.errorCode ? { errorCode: payload?.errorCode } : undefined;
            let lastQuestion = state.questionAnswerList[state.questionAnswerList?.length - 1];
            if (lastQuestion.status == QUESTION_ANSWERED || lastQuestion.status == QUESTION_TIMEOUT) {
                state.isLastQuestionInProgress = false;
            } else {
                state.isLastQuestionInProgress = true;
                // lastQuestion.questionDelivered = new Date().getTime();
            }


        },
        setCorrectAnswerList: (state, { payload }) => {
            let lastQuestion = state.questionAnswerList[state.questionAnswerList.length - 1]
            lastQuestion.linkedTrackingCode = payload?.linkedTrackingCode;
            lastQuestion.questionNote = payload?.questionNote;
            lastQuestion.thanksNote = payload?.thanksNote;
            lastQuestion.answerNote = payload?.answerNote;
            lastQuestion.feedback = payload?.feedback;
            lastQuestion.isCorrectlyAnswered = payload?.isCorrectlyAnswered;
            lastQuestion.optionList = lastQuestion.optionList.map((option) => {
                let payloadoption = payload.optionList.find(payloadoption => option.answerKey == payloadoption.answerKey)
                if (payloadoption) {
                    return payloadoption;
                }
                return option;
            });

        },

        setLastQuestionStatus: (state, { payload }) => {
            let lastQuestion = state.questionAnswerList[state.questionAnswerList.length - 1];
            lastQuestion.status = payload;
            state.isLastQuestionInProgress = undefined
            if (payload === QUESTION_NEW)
                state.isLastQuestionInProgress = true
            else if (payload === QUESTION_ANSWERED || payload === QUESTION_TIMEOUT)
                state.isLastQuestionInProgress = false

        },

        updateLastQuestionOptionSelectionList(state, { payload }) {
            let lastQuestion = state.questionAnswerList[state.questionAnswerList.length - 1];
            payload.forEach(optionId => {
                let selectedOption = lastQuestion.optionList.find(option => option.answerKey == optionId)
                selectedOption.isSelected = true;
            });
            lastQuestion.status = QUESTION_ANSWERED;
            state.isLastQuestionInProgress = false
        },

        setTrackingCode: (state, { payload }) => {
            state.trackingCode = payload;
        },

        updateErrorInfo: (state, { payload }) => {
            state.errorInfo = payload;
        },
        reset: () => initialState,
    },
});

export const {
    setQuestionAnswerList,
    setCorrectAnswerList,
    updateLastQuestionOptionSelectionList,
    setTrackingCode,
    setLastQuestionStatus,
    updateErrorInfo,
    reset
} = QuestionAnswerSlice.actions;



export const prepareAnswerList = (selectedAnswerKeys, status, trackingCode, timeElapsed, trackingVisitId) => {
    // console.log("prepareAnswerList ", selectedAnswerKeys, trackingCode, timeElapsed)

    if (status !== QUESTION_TIMEOUT)
        return {
            trackingCode: trackingCode,
            timeToAnswer: timeElapsed,
            trackingVisitId: trackingVisitId,
            selectedAnswerKeys: selectedAnswerKeys,
            answered: true
        }
    else return {
        trackingCode: trackingCode,
        trackingVisitId: trackingVisitId,
        selectedAnswerKeys: selectedAnswerKeys,
        timeout: true
    }

};

export const isQuestionInProgress = (question) => {
    return question?.status == QUESTION_NEW
}

export default QuestionAnswerSlice.reducer;
