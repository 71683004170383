import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
};

const InfoBannerSlice = createSlice({
    name: "info",
    initialState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload.filter((el) => !!el.title && !!el.score);
        },
    },
});

export const { setData } = InfoBannerSlice.actions;
export default InfoBannerSlice.reducer;
