const ja = {
    translation: {
        //original: translated
        "Last 30 days": "過去30日間",
        "company logo": "会社のロゴ",
        "You have x pending questions": "<span class='pending-number'>{{amount}}</span> 件の保留中の質問があります",
        "You have x pending question": "<span class='pending-number'>{{amount}}</span> 件の保留中の質問があります",
        "x pending question": "<span class='pending-number'>{{amount}}</span> 件の保留中の質問",
        "x pending questions": "<span class='pending-number'>{{amount}}</span> 件の保留中の質問",
        "Get my score": "自分のスコアを取得する",
        "Time remaining...": "残り時間...",
        Score: "スコア",
        "Peer Group Score": "ピア グループ スコア",
        Engagement: "エンゲージメント",
        "Excellence Score": "優秀スコア",
        "Your Score (This Month)": "あなたのスコア (今月)",
        "Your Engagement (This Month)": "あなたのエンゲージメント (今月)",
        "Your Excellence Score (This Month)": "あなたの優秀スコア (今月)",
        "Your Knowledge Score (last 3 months)": "あなたの知識スコア (過去 3 か月)",
        "Your Knowledge Score (last 3 months) Detail": "あなたの知識スコア (過去 3 か月) の詳細",
        "Your Performance (rolling 6 months)": "あなたのパフォーマンス (6 か月連続)",
        "Your Question Answer Rate (Engagement)": "あなたの質問の回答率 (エンゲージメント)",
        Low: "注意",
        High: "高",
        Performance: "パフォーマンス",
        "Peer Group": "ピア グループ",
        "High Performance Zone": "ハイ パフォーマンス ゾーン",
        "Invalid Question": "無効な質問",
        "Invalid link": "無効なリンク",
        "Invalid Tracking Code": "無効なトラッキング コード",
        "Engagement Rate": "エンゲージメント率",
        "Competency Score": "コンピテンシー スコア",
        "Volume Of Questions Answered": "回答された質問の量",
        "Click here to answer your question": "ここをクリックして質問に答えてください",
        "question illustration": "質問イラスト",
        Answered: "回答済み",
        Timeout: "タイムアウト",
        "Get My Score!": "自分のスコアを取得する",
        Correct: "正解",
        Incorrect: "不正解",
        "x% of others answered this question correctly over the last 3 months.":
            "過去 3 か月間で、他の <span class='donut-chart-percentage'>{{amount}}%</span> がこの質問に正しく回答しました。",
        "Click on the related bar to visit associated training material if available": "利用可能な場合は、関連バーをクリックして、関連するトレーニング資料にアクセスします。",
        Submit: "送信",
        "Your Compliance Score (rolling 6 months)": "コンプライアンス スコア (6 か月連続)",
        title: {
            infoBanner: {
                engagementRate: "エンゲージメント率",
                competencyScore: "コンピテンシー スコア",
                excellenceScore: "優秀スコア",
                volumeOfQuestionsAnswered: "回答された質問の量",
            },
        },
        tooltip: {
            infoBanner: {
                engagementRate: "送信日に基づいて回答された質問の割合。",
                competencyScore: "回答日に基づいて正しく回答された質問の割合。",
                excellenceScore: "エンゲージメント スコアとコンピテンシー スコアの積。",
            },
            chart: {
                yourScoreThisMonth: "今月 1 日からの正答率と同僚の平均スコア。",
                yourEngagementThisMonth: "今月送信した質問に対する回答の割合。",
                yourExcellenceScoreThisMonth: "エンゲージメント スコアとコンピテンシー スコアの積。",
                yourKnowledgeScoreLast3Months: "最高から最低までランク付けされたカテゴリ別のコンピテンシー スコア。",
                yourComplianceScoreRolling6Months:
                    "過去 12 か月間の各質問に対する最後の介入に基づいたスコア。質問に正解するまでに 3 回の試行が必要な場合は、最後の回答が正解であるため、スコアは 100% になります。",
            },
        },
        "We were not able to connect to the server, please retry the question link tomorrow.": "サーバーに接続できませんでした。明日、質問リンクを再試行してください。",
        "question video": "質問ビデオ",
        errorMessages: {
            questionExpired: "質問リンクの有効期限が切れました",
            questionOpened: "質問は既にオープンしています",
            questionRemovedByAdmin: "この質問は管理者によって削除されました",
            questionAccess: "質問へのアクセスに失敗しました",
            fileAccess: "ファイルへのアクセスに失敗しました",
            internalServer: "予期しない内部サーバー エラーが発生しました",
            invalidRequest: "無効な要求",
        },
        "We were not able to connect to the server. Refreshing in": "サーバーに接続できませんでした。{{leftSecond}} で更新中",
        "CONGRATULATIONS": "おめでとうございます",
        "Thank you for your contribution":
            "ご協力ありがとうございます",
        notification: {
            openNotifications: "通知を見る",
            noData: "先月以来の通知はありません",
            exportFileName: "通知",
            header: "通知",
            columns: {
                notificationTime: "通知時間",
                explanation: "メッセージ"
            }
        }

    },
};

export default ja;
