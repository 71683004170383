import { NavLink } from "react-router-dom";

const NavLinkTxt = ({ innerHtml, toLink, className, dataTestId, tabIndex, target }) => {
    return <NavLink
        data-testid={"NavLink" + dataTestId}
        reloadDocument
        tabIndex={tabIndex}
        onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
                e.currentTarget.click();
            }
        }}
        to={toLink}
        className={className}
        target={target}
        aria-label={(new DOMParser().parseFromString(innerHtml, 'text/html'))?.body?.textContent || ''}
    >
        <span
            aria-label={innerHtml}
            data-testid={dataTestId}
            dangerouslySetInnerHTML={{
                __html: innerHtml,
            }}
        ></span>

    </NavLink>
}
export default NavLinkTxt;
