import { Grid, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AngularGaugeWithTwoAxes from "../charts/AngularGaugeWithTwoAxes";
import BarAndDot from "../charts/BarAndDot";
import ChartLayout from "../charts/ChartLayout";
import LineChart from "../charts/LineChart";
import PerformanceByCategory from "./PerformanceByCategory";
import axios from "axios";
import ComplianceScore from "./ComplianceScore";
import useAuth from "../../functions/auth";
import { withTranslation } from "react-i18next";

const PerformanceChartTable = (tracking) => {
  const data = useSelector((state) => state.performance?.data);
  const [modalOpen, setModalOpen] = useState(false);
  const [byCategoryData, setByCategoryData] = useState(false);
  const [trainingLinkSwitch, setTrainingLinkSwitch] = useState(false);
  const complianceScoreData = useSelector((state) => state.compliance.complianceScoreData);
  const colorThemeMui = useSelector((state) => state.theme.colorThemeMui);

  const { authorization } = useAuth();


  const handleClick = (catId) => {
    setModalOpen(true);
    if (!!tracking && !!catId && catId !== -1) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/performanceByCategory?q=${tracking.tracking}&selectedCategory=${catId}`, {
          headers: {
            Authorization: authorization,
          },
        })
        .then((r) => {
          if (!!r.data && Object.keys(r.data).length !== 0) {
            const data = r.data[0];
            if (!!data && Object.keys(data).length !== 0) {
              setTrainingLinkSwitch(data.trainingLinkSwitch === 1);
              data.performanceData.forEach((el) => {
                el.color = `rgb(103, 54, 244)`;
              });
              setByCategoryData(data.performanceData);
            }
          }
        })
        .catch(() => {
          setByCategoryData(null);
        });
    }
  };
  const handleClose = () => {
    setModalOpen(false);
    setByCategoryData(null);
  };
  useEffect(() => {
    let tmp = [];
    !!data &&
      data.length > 0 &&
      data.forEach((item) => {
        let chartId = item.title + "_chartDiv";
        let { root, chart } = { root: undefined, chart: undefined };
        switch (item.type) {
          case "AngularGauge":
            ({ root, chart } = AngularGaugeWithTwoAxes({
              data: item.data,
              chartId: chartId,
              colorThemeMui: colorThemeMui,
            }));
            tmp.push({ root, chart });
            break;
          case "BarAndDot":
            ({ root, chart } = BarAndDot({
              data: item.data,
              chartId: chartId,
              handleClick: handleClick,
              colorThemeMui: colorThemeMui,
            }));
            tmp.push({ root, chart });
            break;
          case "Line":
            ({ root, chart } = LineChart({
              data: item.data,
              chartId: chartId,
              colorThemeMui: colorThemeMui,
            }));
            tmp.push({ root, chart });
            break;
          default:
            break;
        }
      });

    return () => {
      tmp.forEach((el) => {
        !!el.root && el.root.dispose();
      });
    };
  }, [data]);

  return (
    <Box data-testid="performanceContainer">
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "15px",
        }}
      >
        {!!data &&
          data.length > 0 &&
          data.map((item) => {
            const chartId = item.title + "_chartDiv";
            return (
              <Grid
                item
                xs={12}
                /* // TODO if 2 charts of a row asked to be done, written commented code on 2 Nov 2022
                                        sm={
                                            item.type === "AngularGauge" ||
                                            item.type === "Line"
                                                ? 4
                                                : 12
                                        }
                                    */
                lg={item.type === "AngularGauge" ? 4 : item.type === "Line" ? 6 : 8}
                key={item.title}
                sx={{
                  marginBottom: 5,
                }}
              >
                <ChartLayout
                  title={item.title}
                  helpToolTip={item.helpToolTip}
                  additionalHeight={item?.type === "BarAndDot" && item?.data?.length > 6 ? (item?.data?.length - 6) * 30 : 0}
                >
                  <div id={chartId} tabIndex={item.type === "Line" ? 0 : -1} data-testid="chart-testid" style={{ width: "100%", height: "100%" }}></div>
                </ChartLayout>
              </Grid>
            );
          })}
        {complianceScoreData !== -1 && (
          <Grid lg={6} item xs={12}>
            <ComplianceScore complianceScoreData={complianceScoreData} />
          </Grid>
        )}
        <PerformanceByCategory modalOpen={modalOpen} handleClose={handleClose} handleClick={handleClick} byCategoryData={byCategoryData} trainingLinkSwitch={trainingLinkSwitch} />
      </Grid>
    </Box>
  );
};
export default withTranslation()(PerformanceChartTable);
