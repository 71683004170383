const en = {
    translation: {
        //original: translated
        "Last 30 days": "Last 30 days",
        "company logo": "company logo",
        "You have x pending questions": "You have <span class='pending-number'>{{amount}}</span> pending questions",
        "You have x pending question": "You have <span class='pending-number'>{{amount}}</span> pending question",
        "x pending question": "<span class='pending-number'>{{amount}}</span> pending question",
        "x pending questions": "<span class='pending-number'>{{amount}}</span> pending questions",
        "Get my score": "Get my score",
        "Time remaining...": "Time remaining...",
        Score: "Score",
        "Peer Group Score": "Peer Group Score",
        Engagement: "Engagement",
        "Excellence Score": "Excellence Score",
        "Your Score (This Month)": "Your Score (This Month)",
        "Your Engagement (This Month)": "Your Engagement (This Month)",
        "Your Excellence Score (This Month)": "Your Excellence Score (This Month)",
        "Your Knowledge Score (last 3 months)": "Your Knowledge Score (last 3 months)",
        "Your Knowledge Score (last 3 months) Detail": "Your Knowledge Score (last 3 months) Detail",
        "Your Performance (rolling 6 months)": "Your Performance (rolling 6 months)",
        "Your Question Answer Rate (Engagement)": "Your Question Answer Rate (Engagement)",
        Low: "Low",
        High: "High",
        Performance: "Performance",
        "Peer Group": "Peer Group",
        "High Performance Zone": "High Performance Zone",
        "Invalid Question": "Invalid Question",
        "Invalid link": "Invalid link",
        "Invalid Tracking Code": "Invalid Tracking Code",
        "Engagement Rate": "Engagement Rate",
        "Competency Score": "Competency Score",
        "Volume Of Questions Answered": "Volume Of Questions Answered",
        "Volume Of Questions Sent": "Volume Of Questions Sent",
        "Click here to answer your question": "Click here to answer your question",
        "question illustration": "question illustration",
        Answered: "Answered",
        Timeout: "Timeout",
        "Get My Score!": "Get My Score!",
        Correct: "Correct",
        Incorrect: "Incorrect",
        "x% of others answered this question correctly over the last 3 months.":
            "<span class='donut-chart-percentage'>{{amount}}%</span> of others answered this question correctly over the last 3 months.",
        "Click on the related bar to visit associated training material if available": "Click on the related bar to visit associated training material if available",
        Submit: "Submit",
        "Your Compliance Score (rolling 6 months)": "Your Compliance Score (rolling 6 months)",
        title: {
            infoBanner: {
                engagementRate: "Engagement Rate",
                competencyScore: "Competency Score",
                excellenceScore: "Excellence Score",
                volumeOfQuestionsAnswered: "Volume Of Questions Answered",
            },
        },
        tooltip: {
            infoBanner: {
                engagementRate: "Proportion of questions answered based on sent date.",
                competencyScore: "Proportion of questions answered correctly based on answer date.",
                excellenceScore: "Engagement score times Competency score.",
            },
            chart: {
                yourScoreThisMonth: "Proportion of correct answers from the 1st of this month and average score of your peers.",
                yourEngagementThisMonth: "Proportion of answered questions that we sent to you this month.",
                yourExcellenceScoreThisMonth: "Engagement score times Competency score.",
                yourKnowledgeScoreLast3Months: "Competency score by category ranked highest to lowest.",
                yourComplianceScoreRolling6Months:
                    "You score based on the last intervention with each question over the prior 12 months. If it takes you 3 attempts to get a question correct, the last answer was a correct answer and so scores 100%.",
            },
            laural: {
                central: {
                    engPercent: "The central engagement score is your average engagement year to date.",
                },
                footer: {
                    narrative:
                        "Each laurel represents a calendar year with each petal pair representing a month. The pairs start empty and turn green if you achieve both your minimum engagement score and your minimum competency score or turn grey if either of those is not achieved. You can hover on each pair to see your engagement and competency score for that month. The central engagement score is your average engagement year to date.",
                },
            },
        },
        "We were not able to connect to the server, please retry the question link tomorrow.":
            "We were not able to connect to the server, please retry the question link tomorrow.",
        "question video": "question video",
        errorMessages: {
            questionExpired: "Question link expired, this link can be deleted",
            questionOpened: "Question is already opened, revert to opened question or try again later",
            questionRemovedByAdmin: "This question has been removed by the administrator",
            questionAccess: "Question Access Failed",
            fileAccess: "File access failed",
            internalServer: "Internal Server Error",
            invalidRequest: "Invalid Request",
            accessDenied: "Access Denied,you do not have permissions to access this link  ",
            sendMessageToEmail: "Please send the message and the error code to {{email}}.",
            sendMessageToSupport: "Please send the message and the error code to Nelly Support.",
            unableToConnectRetryTomorrow: "We were not able to connect to the server, please retry the link tomorrow.",
            noQuestionFound: "No question found",
            dataCannotBeDisplayed: "Data can not be displayed",
            noPerformanceData: "Performance data is not available",
            networkError: "Network Error - Please contact your network administrator",
            questionRemoved: "This question has been removed by the administrator. Your score has not been affected",
            unexpectedError: "Unexpected Error",
            answerError: "Unable to send the answer",
        },
        "We were not able to connect to the server. Refreshing in": "We were not able to connect to the server. Refreshing in {{leftSecond}}",
        "Open Next Question": "Open Next Question",
        CONGRATULATIONS: "CONGRATULATIONS",
        "Thank you for your contribution": "Thank you for your contribution",
        "Show timer": "Show timer",
        "Hide timer": "Hide timer",
        CorrectAndSelected: "Correct and Selected",
        feedbackClickMessage: "here",
        notification: {
            openNotifications: "See notifications",
            noData: "You do not have notification since last month",
            exportFileName: "Notifications",
            header: "Notifications",
            columns: {
                notificationTime: "Time",
                explanation: " Message",
            },
        },
    },
};

export default en;
