
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    data: [],
    unreadNotificationQty: 4,
    notificationCount: 0,
};

const NotificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotifications: (state, { payload }) => {
            state.data = payload;
        },
        // setNotificationRead: (state, { payload }) => {
        //     console.log(state.data, payload)
        //     const notificationToUpdate = state.data.find(notification => notification.id === payload?.id);
        //     if (notificationToUpdate) {
        //         notificationToUpdate.read = payload?.isRead;
        //     }
        //     console.log("notificationToUpdate", notificationToUpdate, state)
        // },
        updateUnreadNotificationQty: (state, { payload }) => {
            state.unreadNotificationQty = payload?.nunreadCount;
        },
        updateNotificationCounts: (state, { payload }) => {
            state.unreadNotificationQty = payload?.nunreadCount;
            state.notificationCount = payload?.notificationCount;
        },

    },
});

export const {
    setNotifications,
    // setNotificationRead,
    updateUnreadNotificationQty,
    updateNotificationCounts
} = NotificationSlice.actions;

export default NotificationSlice.reducer;




