import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import { Provider } from "react-redux";
import App from "./App";
import { addLicense } from "@amcharts/amcharts5";
import "./internationalization/i18n";
import { LicenseInfo } from "@mui/x-license-pro";

addLicense(process.env.REACT_APP_AM5_LICENSE);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

const root = ReactDOM.createRoot(document.getElementById("root"));


const reactEnv = process.env.REACT_APP_ENV;

root.render(
    <>
        {reactEnv === "local" ?
            (
                <React.StrictMode>
                    <Provider store={store}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </React.StrictMode>
            ) : (
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            )
        }
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
