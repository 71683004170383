import { useLayoutEffect } from "react";
import * as am5percent from "@amcharts/amcharts5/percent";
import { useTheme } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import am5responsive from "@amcharts/amcharts5/themes/Responsive";
import am5animated from "@amcharts/amcharts5/themes/Animated";
import thumbsupSvg from "../svgIcons/thumbs_up.svg";
import thumbsupAccessibleSvg from "../svgIcons/thumbs_up_accessibility.svg";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./DonutChart.sass";

const DonutChart = (props) => {
    const theme = useTheme();
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);

    useLayoutEffect(() => {
        if (!props.data || !!props.dontDraw) {
            return;
        }
        // setTimeout(() => { //timeout solves font loading problem as a workaround
        const root = am5.Root.new(props.divId);
        const responsive = am5responsive.new(root);
        const themes = [responsive, am5animated.new(root)];
        root.setThemes(themes);
        root.tabindex = 0;
        const chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                radius: am5.percent(95),
                innerRadius: am5.percent(75),
                layout: root.gridLayout,
                width: am5.p100,
                focusable: false,
            })
        );
        const seriesTooltip = am5.Tooltip.new(root, {
            labelText: "{category}: {value}",
            ariaLabel: "{category}: {value}",
            role: "tooltip",
            layout: root.gridLayout,
            layer: 2,
        });
        const series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: "Series",
                categoryField: Object.keys(props.data[0])[0],
                valueField: Object.keys(props.data[0])[1],
                tooltip: seriesTooltip,
                ariaHidden: !Object.keys(props.data[0])[1],
            })
        );
        if (customStyle === "nelly accessible") {
            series.get("colors").set("colors", ["#C04000", "#138808"]);
        } else {
            series.get("colors").set("colors", ["#EB906B", "#5FC07A"]);
        }
        series.data.setAll(props.data);
        seriesTooltip.get("background").set("fillOpacity", 1);
        seriesTooltip.label.setAll({
            breakWords: true,
            oversizedBehavior: "wrap",
            maxWidth: chart.innerWidth() - 10,
        });
        series.labels.template.set("forceHidden", true);
        series.ticks.template.set("visible", false);
        series.slices.template.setAll({
            toggleKey: "none",
            focusable: true,
            hoverOnFocus: true,
            isMeasured: true,
            ariaLabel: "{category} {value}",
        });

        series.events.on("datavalidated", function () {
            am5.array.each(series.dataItems, function (dataItem) {
                if (dataItem.get("value") === 0) {
                    dataItem.hide();
                }
            });
        });

        // Add Center Info
        const containerCenter = root.tooltipContainer.children.push(
            am5.Container.new(root, {
                layout: root.verticalLayout,
                x: am5.p50,
                y: am5.p50,
                centerX: am5.p50,
                centerY: am5.p50,
                layer: 1,
            })
        );
        const labelCenter1 = am5.Picture.new(root, {
            fontSize: 30,
            paddingBottom: 10,
            centerX: am5.p50,
            src: customStyle === "nelly accessible" ? thumbsupAccessibleSvg : thumbsupSvg,
            position: "relative",
        });
        containerCenter.children.push(labelCenter1);
        return () => {
            root.dispose();
        };
    }, [props, theme.palette]);
    return (
        <div
            data-testid="donutChart"
            className="break-down-chart"
            tabIndex={0}
            aria-label={props?.data.map((el) => {
                return `${props.t(el.dataType)}: ${el.value}.`;
            })}
        >
            <div
                id={props.divId}
                style={{
                    width: "100%",
                    height: "150px",
                }}
            />
        </div>
    );
};
export default withTranslation()(DonutChart);
