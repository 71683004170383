import * as React from 'react';
import { Box, CircularProgress, Grid, Dialog, Button, Modal, useTheme, useMediaQuery } from "@mui/material";
import { withTranslation } from "react-i18next";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowForward } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import "./PreQuestion.sass";
import useAuth from '../../../functions/auth';
import QuestionAnswer from "./QuestionAnswer";
import {
    setQuestionAnswerList, updateErrorInfo
} from "./QuestionAnswerSlice";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { setDisplayButtonToAnswer } from "../../base/DashboardInfoQASlice";
import { fetchData, endPoints } from "./DataProvider";
import ErrorHandler from "../fallback/ErrorHandler"
import variables from "../../../variables.sass";

const PreQuestion = ({ t }) => {

    const errorInfo = useSelector((state) => state.questionAnswer.errorInfo)


    return (
        <ErrorHandler data-testid="PreQuestionError" baseComponent={<PreQuestionUI t={t} />} errorInfo={errorInfo} t={t} />
    );
}
const PreQuestionUI = ({ t }) => {
    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);

    const dispatch = useDispatch();
    const cookies = new Cookies();

    const { isSso, company, authorization } = useAuth();

    let [preQuestionInfo, setPreQuestionInfo] = useState(undefined);
    const [msgCountDownVal, setMsgCountDownVal] = useState(0)


    let displayButtonToAnswer = useSelector((state) => state.dashboardInfoQA?.displayButtonToAnswer)
    let setbuttonToAnswerCookies = useSelector((state) => state.dashboardInfoQA?.setbuttonToAnswerCookies)

    let previewMode = useSelector((state) => state.dashboardInfoQA?.previewMode)

    const [preQInfoLoading, setPreQInfoLoading] = useState(true);
    const [questionLoading, setQuestionLoading] = useState(true);

    let willDisplayButtonToAnswer = displayButtonToAnswer;
    let willDisplayLottery = !displayButtonToAnswer && !!preQuestionInfo?.lotteryInfo && !preQInfoLoading;
    let willDisplayQuestionPreMessage = !displayButtonToAnswer && !preQuestionInfo?.lotteryInfo && !preQInfoLoading && (msgCountDownVal > 0 || !!preQuestionInfo?.preMessage?.introText);
    let willDisplayQuestionAnswer = !(willDisplayButtonToAnswer || willDisplayLottery || willDisplayQuestionPreMessage) && !questionLoading && !preQInfoLoading;
    let willDisplayLoading = !(willDisplayButtonToAnswer || willDisplayLottery || willDisplayQuestionPreMessage || willDisplayQuestionAnswer);

    useEffect(() => {
        let canFetchQuestionCondition = !!trackingCode && !displayButtonToAnswer
        if (canFetchQuestionCondition) {
            fetchAllQuestionInfo();
        }
    }, [trackingCode]);

    useEffect(() => {
        if (msgCountDownVal > 0 && willDisplayQuestionPreMessage) {
            const timerId = setTimeout(() => {
                if ((msgCountDownVal - 1) < 0) {
                    setPreQuestionInfo(undefined);
                } else
                    setMsgCountDownVal(msgCountDownVal - 1);
            }, 1000);

            return () => clearTimeout(timerId);
        }
    }, [msgCountDownVal, preQuestionInfo]);

    const startWellbeingJourney = () => {
        // if (preQuestionInfo?.preMessage ? countDown > 0 ) {
        //     setMsgCountDownVal(preQuestionInfo?.preMessage.countDown)
        //     preQuestionInfo?.preMessage.introText = undefined;
        // }
        //todo if both intro msg and video and link warning msg will be supported uncomment these line and delete  setPreQuestionInfo(undefined);
        setPreQuestionInfo(undefined);
    }
    const handleQuestionResponse = (response) => {
        dispatch(setQuestionAnswerList(response));
        setQuestionLoading(false)
    }

    const handlePreQuestionResponse = (response) => {
        let preQuestionInfoResponse = {};
        preQuestionInfoResponse.lotteryInfo = !!response?.lotteryDetailId ? { id: response?.lotteryDetailId, content: response?.lotteryTemplateHTML } : undefined;
        preQuestionInfoResponse.preMessage = response?.preMessage;
        if (!response?.preMessage?.introText)
            setMsgCountDownVal(response?.preMessage?.countDown)
        if (!!response?.lotteryDetailId || !!response?.preMessage)
            setPreQuestionInfo(preQuestionInfoResponse);
        setPreQInfoLoading(false)

    }

    const handlePreQuestionFetchError = (error) => {
        dispatch(updateErrorInfo({ errorMsg: error?.message, customMessage: t("errorMessages.questionAccess") }))
        setPreQInfoLoading(false);

    }

    const handleQuestionFetchError = (error) => {
        dispatch(updateErrorInfo({ errorMsg: error?.message, customMessage: t("errorMessages.questionAccess") }))
        setQuestionLoading(false)
    }

    const fetchQuestion = () => {
        let endpoint = previewMode ? endPoints.PreviewQuestion : endPoints.Question;
        const formData = new URLSearchParams();
        formData.append("q", trackingCode);
        fetchData(endpoint, authorization, "application/x-www-form-urlencoded", formData, handleQuestionResponse, handleQuestionFetchError, trackingCode);
    }

    const fetchAllQuestionInfo = () => {
        if (!previewMode) {
            fetchPreQuestion();
        } else {
            setPreQInfoLoading(false)
        }
        fetchQuestion();
    }

    const fetchPreQuestion = () => {
        const formData = new URLSearchParams();
        formData.append("q", trackingCode);
        fetchData(endPoints.PreQuestion, authorization, "application/x-www-form-urlencoded", formData, handlePreQuestionResponse, handlePreQuestionFetchError, trackingCode);

    }


    const handleClick = () => {
        let tmpDate = new Date();
        tmpDate.setMonth(tmpDate.getMonth() + 11);
        if (setbuttonToAnswerCookies) {
            cookies.set("userPressedButton", "1", {
                path: "/",
                expires: tmpDate,
                secure: true,
            });
        }
        dispatch(setDisplayButtonToAnswer(false));
        fetchAllQuestionInfo();
    };

    const handleLotteryTemplateClose = () => {
        let formData = new URLSearchParams();
        let endpoint = endPoints.Lottery;

        formData.append("q", preQuestionInfo?.lotteryInfo?.id);
        fetchData(endpoint, authorization, "application/x-www-form-urlencoded", formData, undefined, undefined, trackingCode);
        if (!!preQuestionInfo?.preMessage) {
            let preQuestionInfoResponse = {};
            preQuestionInfoResponse.lotteryInfo = undefined;
            preQuestionInfoResponse.preMessage = preQuestionInfo?.preMessage;
            setPreQuestionInfo(preQuestionInfoResponse);
        } else
            setPreQuestionInfo(undefined);
    };

    return <Grid className="Prequestion" data-testid="Prequestion">
        {willDisplayButtonToAnswer && <Button variant={customStyle} onClick={handleClick} className="redirectToQuestionButton" data-testid="ButtonToAnswer">
            <span style={{ paddingRight: 0 }}>{t("Click here to answer your question")}</span>
            <ArrowForward fontSize="inherit" />
        </Button>}
        {willDisplayLoading && <LoadingGrid />}
        {willDisplayLottery && <LotteryComponent handleLotteryTemplateClose={handleLotteryTemplateClose} lotteryInfo={preQuestionInfo?.lotteryInfo} t={t} />}
        {willDisplayQuestionPreMessage && <PreMessageComponent message={preQuestionInfo?.preMessage} t={t} msgCountDownVal={msgCountDownVal} customStyle={customStyle} startWellbeingJourney={startWellbeingJourney} />}
        {willDisplayQuestionAnswer && < QuestionAnswer />}
    </Grid>
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },

    align: 'center'
}));

const LotteryComponent = ({ handleLotteryTemplateClose, t, lotteryInfo }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // Checks if the screen size is small or below

    return (
        <BootstrapDialog
            onClose={handleLotteryTemplateClose}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullScreen={fullScreen} // Fullscreen on small screens
            data-testid="lottery-bootstrapDialog"
        >
            <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    height: fullScreen ? "90%" : "50%",
                    width: fullScreen ? "90%" : "50%",
                    transform: "translate(-50%, -50%)",
                    border: "none",
                    boxShadow: 0,
                    background: "darkgray"
                }} >
                    <DialogTitle sx={{
                        m: 0,
                        p: 2,
                        fontWeight: "bold",
                        height: "20px",
                        textAlign: "center",
                        background: theme.palette.lotteryBanner,
                    }}
                        id="customized-dialog-title">
                        {t(" ")}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleLotteryTemplateClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: theme.palette.black,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <p dangerouslySetInnerHTML={{ __html: lotteryInfo?.content }}></p>
                </Box>
            </Modal>
        </BootstrapDialog>
    );
}
const PreMessageComponent = ({ message, t, msgCountDownVal, customStyle, startWellbeingJourney }) => {
    // console.log("message ", message);
    if (!!message?.introText && !!message.wellbeingIntro) {
        return <Box sx={{
            position: "absolute",
            top: "50%",
            left: "60%",
            width: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            boxShadow: 0,

        }} data-testid="questionInfoIntroMessage" >
            <Grid item xs={12}>
                <p>
                    <h4 style={{ textAlign: "left" }} className={`alert-content  ${customStyle}`}>
                        {t(message.introText)}
                    </h4>

                    <br />
                    {t("Please click")}{" "}
                    <a href="#" onClick={() => startWellbeingJourney()} style={{ color: '#15bd15', fontWeight: 'bold', fontSize: 'large' }} data-testid="linktoquestion">
                        {t("here")}
                    </a>{" "}
                    {t("to start your Wellbeing journey")}
                </p>

            </Grid>
        </Box >
    }
    else if (msgCountDownVal > 0) { //do not show countdown if intro is enabled
        return <Box sx={{
            position: "absolute",
            top: "50%",
            left: "60%",
            width: "50%",
            transform: "translate(-50%, -50%)",
            border: "none",
            boxShadow: 0,

        }} data-testid="questionInfoMessage" >
            <Grid container>
                <Grid item xs={12} >
                    {!!message?.text ? (
                        <Grid item xs={12}>
                            {message?.text?.split(/[\n]+/).map((line, index) => (
                                <h3 key={index} style={{ textAlign: "center", color: variables.nelly }} className={`alert-content  ${customStyle}`}>
                                    {t(line.replace(/\n/g, ''))}
                                </h3>
                            ))}
                            <h3 style={{ textAlign: "center", color: variables.nelly }} className={`alert-content  ${customStyle}`}>
                                {msgCountDownVal}
                            </h3>
                        </Grid>
                    ) : (
                        <h3 style={{ textAlign: "center", color: variables.nelly }} className={`alert-content  ${customStyle}`}>
                            {t(message.text) || t("Message will be displayed in :")} {msgCountDownVal}
                        </h3>
                    )}
                </Grid>
            </Grid>
        </Box >
    }
}

export const LoadingGrid = () => {
    return <Grid container className="loading">
        <Grid item className="loading-box">
            <CircularProgress color="primary" />
        </Grid>
    </Grid>
}
export default withTranslation()(PreQuestion);
