import {withTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import "./QAInfo.sass";
import {useDispatch, useSelector} from "react-redux";
import {endPoints, fetchData} from "../questionAnswer/DataProvider";
import useAuth from "../../../functions/auth";
import {useEffect, useState} from "react";
import {InfoIcon} from "../../icons/InfoIcon";
import ErrorHandler from "../fallback/ErrorHandler";
import {setIsInfoMode} from "../../base/DashboardInfoQASlice";

const QAInfo = ({ t }) => {
    return <ErrorHandler baseComponent={<QAInfoUI t={t} />} t={t} />;
};

const QAInfoUI = ({ t }) => {
    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);

    const { authorization } = useAuth();
    let [qAInfoMessage, setQAInfoMessage] = useState(undefined);

    const dispatch = useDispatch();
    dispatch(setIsInfoMode(true));

    useEffect(() => {
        if(trackingCode){
            fetchAnswerInfo();
        }
    }, [trackingCode]);

    const fetchAnswerInfo = () => {
        const endpoint = endPoints.InfoMessage + "?q=" + trackingCode;
        fetchData(endpoint, authorization, undefined, undefined, handleQAInfoResponse, undefined, trackingCode);
    }

    const handleQAInfoResponse = (response) => {
        setQAInfoMessage(response?.infoMessage);
    }

    return (
        qAInfoMessage?.length > 0 && (
            <Grid item data-testid="qaInfoMes" className="grid-item-note">
                <div className={` qainfonote  ${customStyle}`} id={`current_note_element-qaInfoMessageId`}>
                    <InfoIcon/>
                    <p tabIndex={39} dangerouslySetInnerHTML={{__html: qAInfoMessage}}></p>
                </div>
            </Grid>
        )
    );
}

export default withTranslation()(QAInfo);