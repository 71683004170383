
import { withTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import "./Fallback.sass";
import FallbackQA from "./FallbackQA";

const ErrorHandler = ({ t, baseComponent, errorInfo, displayGetMyScore }) => {
    // const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    const handleFallBack = (error) => {
        // logMessage(new Error("UIUX Error"), trackingCode); //todo enable log front end messages by ensuring sendind one request, 
        return (
            <FallbackQA errorInfo={{ errorMsg: "UIUX Error", customMessage: t("errorMessages.dataCannotBeDisplayed") }}
                displayGetMyScore={displayGetMyScore} />
        );
    }
    return (
        baseComponent ? (
            <ErrorBoundary
                FallbackComponent={(error) => handleFallBack(error)} //this handles front end exceptions
                onError={(error) => {
                    // console.log("onError", error);
                }}
            >
                {errorInfo
                    ?
                    <FallbackQA errorInfo={errorInfo} displayGetMyScore={displayGetMyScore} />
                    :
                    baseComponent
                }
            </ErrorBoundary>)
            :
            <></>
    );
};


export default withTranslation()(ErrorHandler);
