import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Card, CardContent, Typography, Box, IconButton } from '@mui/material';
import "./Notification.sass"
import CloseIcon from '@mui/icons-material/Close';
import { updateUnreadNotificationQty, setNotifications } from "./NotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    endPoints, fetchData
} from "../questionAnswer/DataProvider";
import useAuth from "../../../functions/auth";
import { LoadingGrid } from "../questionAnswer/PreQuestion";
import DataTable from "../utils/DataTable";
import { formatDate } from "../questionAnswer/QuestionAnswer";
const NotificationExplanationDialog = ({ t, handleClose, notifications, sortAndPaginationProps, isReady }) => {




    const columns = [
        {
            field: "notificationTime",
            headerName: t("notification.columns.notificationTime"),
            width: 150,
            sortable: true,
            type: "string",
            renderCell: ({ row }) => {
                if (!row?.isRead)
                    return <span > <strong>{formatDate(row?.notificationTime)}</strong></span >
                else
                    return <span >{formatDate(row?.notificationTime)}</span >
            }
        },
        {
            field: "explanation",
            headerName: t("notification.columns.explanation"),
            width: 300,
            sortable: true,
            type: "string",
            renderCell: ({ row }) => {

                if (!row.isRead)
                    return <Box
                        sx={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        <span><strong>{row?.explanation}</strong></span>
                    </Box>

                else
                    return <Box
                        sx={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        <span>{row?.explanation}</span>
                    </Box>
            },
            flex: 1
        },

    ]

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullScreen
                data-testid="notificationDialog">

                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "50%",
                    transform: "translate(-50%, -50%)",
                    border: "none",
                    boxShadow: 0,
                    background: "white",
                    bordercolor: "lightblue",
                    borderRadius: "10px"
                }} >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            zIndex: "1", // Ensure close button is on top
                            padding: "16px",
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            data-testid="closeBtn"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogTitle>{t("notification.header")}</DialogTitle>
                    <DialogContent>
                        {
                            !isReady ?
                                <LoadingGrid />
                                :
                                notifications?.data.length > 0 ?
                                    <DataTable
                                        headers={columns} //fe pagination
                                        data={notifications?.data}
                                        totalRecords={notifications?.data?.length}
                                        displayStateProps={sortAndPaginationProps}
                                        exportFileName={t("notification.exportFileName")}
                                        getRowId={() => (row) => { return row?.notificationTime }}
                                        isAutoHeightActive={true}
                                    />
                                    :
                                    <Card>
                                        <CardContent>
                                            <Typography>
                                                <br /> {t("notification.noData")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                        }

                    </DialogContent>
                </Box>
            </Dialog>
        </div>

    );

};

const NotificationView = ({ t, setDisplayNotifications }) => {
    const dispatch = useDispatch();
    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    const { authorization } = useAuth();
    const [isReady, setIsReady] = useState(false);
    const notifications = useSelector((state) => state.notifications);

    const initalStateOfSortAndPaginationProps = {
        pageNumber: 1,
        recordsPerPage: 10,
        sortCol: notifications?.columns?.notificationTime,
        sortDirection: "desc",
        pinnedModel: [{
            left: notifications?.columns?.notificationTime,
        }],
        sortModel: notifications?.columns?.notificationTime ? [{ field: notifications?.columns?.notificationTime, sort: "desc" }] : [],
    };

    const [sortAndPaginationProps, setSortAndPaginationProps] = useState(initalStateOfSortAndPaginationProps);

    const updateNotification = (response) => {
        dispatch(setNotifications(response));
        setIsReady(true);
    }
    const handleNotGettingNotification = () => {
        dispatch(setNotifications([]));
        setIsReady(true);
    }

    useEffect(() => {
        let endpoint = endPoints.UserNotification + "?t=" + trackingCode + "&qty=false";
        fetchData(endpoint, authorization, undefined, undefined, updateNotification, handleNotGettingNotification, trackingCode);
    }, []);

    // useEffect(() => {
    //     setSortAndPaginationProps(initalStateOfSortAndPaginationProps);
    // }, [notifications]);

    const handleClose = () => {
        //set all notification as read
        let endpoint = endPoints.UserNotificationRead + "?t=" + trackingCode + "&isRead=1";
        fetchData(endpoint, authorization, undefined, {}, () => dispatch(updateUnreadNotificationQty(0)), undefined, trackingCode);
        setDisplayNotifications(false);
    };
    return <NotificationExplanationDialog t={t} handleClose={handleClose} notifications={notifications} sortAndPaginationProps={sortAndPaginationProps} isReady={isReady} />

}
export default withTranslation()(NotificationView);
