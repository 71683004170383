import { Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PerformanceChartTable from "./PerformanceChartTable";
import { setPerformanceData } from "./PerformanceSlice";
import { setComplianceScoreData } from "./ComplianceSlice";
import { logMessage } from "../common/questionAnswer/DataProvider";
import ErrorHandler
    from "../common/fallback/ErrorHandler";
import {
    updateErrorInfo
} from "../common/questionAnswer/QuestionAnswerSlice";
import useAuth
    from "../../functions/auth";
const PerformanceQA = ({ t }) => {
    const errorInfo = useSelector((state) => state.questionAnswer.errorInfo)
    return (
        <ErrorHandler baseComponent={<PerformanceQAUI t={t} />} errorInfo={errorInfo} t={t} />
    );
}
const PerformanceQAUI = ({ t }) => {
    const [tracking, setTracking] = useState("");
    const [done, setDone] = useState(false);
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (params.get("t")) {
            setTracking(params.get("t"));
        } else {
            throw new Error(t("Invalid Link"))
        }
    }, [navigate, params]);

    const { authorization } = useAuth();

    const calculatePerformanceAndEngagement = (backData, chartData) => {
        let tmpScore = backData.performances?.find((el) => parseInt(el.reportDate.split("-")[1]) === new Date().getMonth() + 1);
        if (tmpScore) {
            chartData.push({
                title: t("Your Score (This Month)"),
                helpToolTip: t("tooltip.chart.yourScoreThisMonth"),
                type: "AngularGauge",
                data: [
                    {
                        name: t("Score"),
                        value: Math.round(tmpScore.performance),
                    },
                    {
                        name: t("Peer Group Score"),
                        value: Math.round(tmpScore.peerGroup),
                    },
                ],
            });
        }
        let tmpEngagement = backData.engagements?.find((el) => parseInt(el.reportDate.split("-")[1]) === new Date().getMonth() + 1);
        if (tmpEngagement) {
            chartData.push({
                title: t("Your Engagement (This Month)"),
                helpToolTip: t("tooltip.chart.yourEngagementThisMonth"),
                type: "AngularGauge",
                data: [
                    {
                        name: t("Engagement"),
                        value: Math.round(tmpEngagement.engagement),
                    },
                ],
            });
        }
        if (!!tmpScore && !!tmpEngagement) {
            chartData.push({
                title: t("Your Excellence Score (This Month)"),
                helpToolTip: t("tooltip.chart.yourExcellenceScoreThisMonth"),
                type: "AngularGauge",
                data: [
                    {
                        name: t("Excellence Score"),
                        value: Math.round((tmpScore.performance * tmpEngagement.engagement) / 100),
                    },
                ],
            });
        }
    }
    const calculateKnowledgeScores = (backData, chartData) => {
        let tmpPassData = JSON.parse(JSON.stringify(backData.knowledgeScores));
        tmpPassData.forEach((el) => {
            el.color = `rgb(103,54,244)`;
        });
        chartData.push({
            title: t("Your Knowledge Score (last 3 months)"),
            helpToolTip: t("tooltip.chart.yourKnowledgeScoreLast3Months"),
            type: "BarAndDot",
            data: tmpPassData,
        });
    }
    const calculateXMonthPerformance = (backData, chartData) => {
        chartData.push({
            title: t("Your Performance (rolling 6 months)"),
            type: "Line",
            data: backData.performances
                .map((item) => {
                    let tmpDate = new Date(item.reportDate.split("-")[0], item.reportDate.split("-")[1] - 1, 15).getTime();
                    delete item.reportDate;
                    return {
                        ...item,
                        date: tmpDate,
                    };
                })
                .sort((a, b) => a.date - b.date),
        });
    }
    const calculateEngagement = (backData, chartData) => {
        chartData.push({
            title: t("Your Question Answer Rate (Engagement)"),
            type: "Line",
            data: backData.engagements
                .map((item) => {
                    let tmpDate = new Date(item.reportDate.split("-")[0], item.reportDate.split("-")[1] - 1, 15).getTime();
                    delete item.reportDate;
                    return {
                        ...item,
                        date: tmpDate,
                    };
                })
                .sort((a, b) => a.date - b.date),
        });
    }
    const calculateChartData = (backData) => {
        let chartData = [];
        if (backData.performances || backData.engagements) {
            calculatePerformanceAndEngagement(backData, chartData)
        }
        if (backData.knowledgeScores) {
            calculateKnowledgeScores(backData, chartData)
        }
        if (backData.performances && backData.performances.length > 1) {
            calculateXMonthPerformance(backData, chartData)
        }
        if (backData.engagements && backData.engagements.length > 1) {
            calculateEngagement(backData, chartData)
        }
        return chartData
    }
    const preparePerfomanceData = (tracking) => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/performance?q=${tracking}`, {
                headers: {
                    Authorization: authorization,
                },
            })
            .then((r) => {
                const backData = r.data;
                if (!backData || Object.keys(backData).length === 0) {
                    dispatch(setPerformanceData());
                } else {
                    let chartData = calculateChartData(backData)
                    dispatch(setPerformanceData(chartData));
                    setDone(true);
                }
            })
            .catch((error) => {
                logMessage(error, tracking, "complianceScore")
                dispatch(updateErrorInfo({ errorMsg: error?.message, customMessage: t("errorMessages.noPerformanceData") }))
            });
    }
    const prepareComplianceData = (tracking) => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/complianceScore?q=${tracking}`, {
                headers: {
                    Authorization: authorization,
                },
            })
            .then((r) => {
                if (!!r.data && Object.keys(r.data).length !== 0) {
                    const data = { ...r.data[0] };

                    if (!!data.chartActive && data.chartActive === "0") {
                        dispatch(setComplianceScoreData(-1));
                    } else if (!!data.complience && Object.keys(data.complience).length !== 0) {
                        const compliance = [...data.complience];
                        compliance.forEach((el) => {
                            let tmpDate = new Date(el.reportDate.split("-")[0], el.reportDate.split("-")[1] - 1, 15).getTime();
                            delete el.reportDate;
                            el.date = tmpDate;
                        });

                        dispatch(setComplianceScoreData(compliance.sort((a, b) => a.date - b.date)));
                    }
                } else {
                    dispatch(setComplianceScoreData());
                }
            })
            .catch((error) => {
                logMessage(error, tracking, "performance")
            }); //todo error handler
    }
    useEffect(() => {
        if (tracking) {
            preparePerfomanceData(tracking);
            prepareComplianceData(tracking)
        }
    }, [tracking]);

    return (
        <>
            {done && (
                <Grid
                    container
                    spacing={1}
                    sx={{
                        padding: 0,
                        height: "100%",
                    }}
                    data-testid="performanceCharts"
                >
                    <PerformanceChartTable tracking={tracking} />
                </Grid>
            )}
        </>
    );
};
export default withTranslation()(PerformanceQA);
