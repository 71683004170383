import { Card, Modal } from "@mui/material";
import ChartLayout from "../charts/ChartLayout";
import { useEffect } from "react";
import BarAndDot from "../charts/BarAndDot";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

let style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "60%",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const PerformanceByCategory = ({ t, modalOpen, handleClose, handleClick, byCategoryData, trainingLinkSwitch }) => {
    const colorThemeMui = useSelector((state) => state.theme.colorThemeMui);

    useEffect(() => {
        let { root, chart } = { root: undefined, chart: undefined };

        const chartDiv = document.getElementById("bySubCat_chartDiv");
        if (!!modalOpen && !!byCategoryData && !!chartDiv) {
            ({ root, chart } = BarAndDot({
                data: byCategoryData,
                chartId: "bySubCat_chartDiv",
                handleClick: handleClick,
                colorThemeMui: colorThemeMui,
            }));
        }
        return () => {
            if (root) {
                root.dispose();
            }
        };
    }, [byCategoryData]);

    if (byCategoryData?.length > 6) {
        style.overflow = "scroll";
        style.height = Math.min(65 + (byCategoryData?.length - 7) * 5, 80) + "%";
        style.display = "block";
    } else {
        style.overflow = undefined;
        style.height = undefined;
        style.display = undefined;
    }

    return (
        <Modal open={modalOpen} onClose={handleClose}>
            <Card sx={style}>
                <ChartLayout title={t("Your Knowledge Score (last 3 months) Detail")} additionalHeight={byCategoryData?.length > 6 ? (byCategoryData?.length - 6) * 30 : 0}>
                    <div id="bySubCat_chartDiv" data-testid="chart-testid" style={{ width: "100%", height: "100%" }}></div>
                </ChartLayout>
                {trainingLinkSwitch && (
                    <div
                        style={{
                            height: "150",
                            textAlign: "center",
                            backgroundColor: "rgb(239, 235, 252)",
                        }}
                    >
                        {t("Click on the related bar to visit associated training material if available")}
                    </div>
                )}
            </Card>
        </Modal>
    );
};

export default withTranslation()(PerformanceByCategory);
