import { Divider, Drawer, Container, Box } from "@mui/material";
import { DRAWER_WIDTH } from "../../constants";
import "./Sidebar.sass";
import variables from "../../variables.sass";
import InfoBanner from "../common/infoBanner/InfoBanner";
import { useSelector } from "react-redux";
import OthersInfoQA from "../othersInfo/OthersInfoQA";
import { withTranslation } from "react-i18next";
import ErrorHandler from "../common/fallback/ErrorHandler";

const SidebarQA = ({ t, open, isDesktop }) => {
    const info = useSelector((state) => state.info);
    const dashboardInfo = useSelector((state) => state.dashboardInfoQA);
    const isLastQuestionInProgress = useSelector((state) => state.questionAnswer.isLastQuestionInProgress);
    let displayInfoChart = isLastQuestionInProgress != undefined && !isLastQuestionInProgress && open;

    return (
        <Drawer
            sx={(theme) => {
                return {
                    transition: theme.transitions.create(["display", "transform"]),
                    display: open ? "flex" : "none",
                    width: DRAWER_WIDTH,
                    position: "relative",
                    boxShadow: `2px 2px 20px ${variables.gray2}`,
                    "& .MuiDrawer-paper": {
                        position: "static",
                        padding: "30px",
                        backgroundColor: "transparent",
                        width: "100%",
                        justifyContent: "start",
                        flex: 1,
                        boxSizing: "border-box",
                        border: "none",
                    },
                };
            }}
            variant="persistent"
            anchor="left"
            data-testid="sidebar"
            open={open}
        >
            <Container style={{ flex: "1", display: "flex", flexDirection: "column", padding: "5px" }}>
                <Box style={{ flex: "1" }}>
                    {!!dashboardInfo?.logo && (
                        <>
                            <img data-testid="company-logo" id="company-logo" src={dashboardInfo.logo} alt="" />
                            <span data-testid="company-logo-label" id="company-logo-label">
                                {t("company logo")}
                            </span>
                        </>
                    )}
                    {!!dashboardInfo?.customLogo && <img data-testid="customlogo-logo" id="custom-logo" src={dashboardInfo.customLogo} alt="" />}
                    {displayInfoChart && <ErrorHandler baseComponent={<OthersInfoQA data-testid="dashboardChart" />} />}

                    {info?.data?.length > 0 && (
                        <>
                            <span tabIndex={0} className="time-span">
                                {t("Last 30 days")}
                            </span>
                            <ErrorHandler baseComponent={<InfoBanner info={info.data} />} />
                        </>
                    )}
                </Box>
            </Container>

            {!!dashboardInfo?.customFooter && (
                <>
                    <Divider sx={{ height: "1rem", borderColor: "transparent" }} />
                    <img src={dashboardInfo.customFooter} width="200px" alt=""></img>
                </>
            )}
            <Divider sx={{ height: "1rem", borderColor: "transparent" }} />
            <img src="/edf_logo.svg" width="200px" alt=""></img>
        </Drawer>
    );
};

export default withTranslation()(SidebarQA);
