const ch = {
    translation: {
        //original: translated
        "Last 30 days": "过去30天",
        "company logo": "公司标志",
        "You have x pending questions": "您有<span class='pending-number'>{{amount}}</span>个问题等待回答",
        "You have x pending question": "您有<span class='pending-number'>{{amount}}</span>个问题等待回答",
        "x pending question": "<span class='pending-number'>{{amount}}</span>个问题等待回答",
        "x pending questions": "<span class='pending-number'>{{amount}}</span>个问题等待回答",
        "Get my score": "获取我的分数",
        "Time remaining...": "剩余时间…",
        Score: "分数",
        "Peer Group Score": "对照组分数",
        Engagement: "参与度",
        "Excellence Score": "优秀分数",
        "Your Score (This Month)": "您的分数（本月）",
        "Your Engagement (This Month)": "您的参与度（本月）",
        "Your Excellence Score (This Month)": "您的优秀分数（本月）",
        "Your Knowledge Score (last 3 months)": "您的知识分数（过去3个月）",
        "Your Knowledge Score (last 3 months) Detail": "您的知识分数（过去3个月）详细信息",
        "Your Performance (rolling 6 months)": "您的表现（连续6个月）",
        "Your Question Answer Rate (Engagement)": "您的问题回答率(参与度)",
        Low: "低",
        High: "高",
        Performance: "表现",
        "Peer Group": "对照组",
        "High Performance Zone": "高分段",
        "Invalid Question": "无效问题",
        "Invalid link": "无效链接",
        "Invalid Tracking Code": "无效的跟踪代码",
        "Engagement Rate": "参与比例",
        "Competency Score": "正确率",
        "Volume Of Questions Answered": "问题回答量",
        "Click here to answer your question": "点击这里回答您的问题",
        "question illustration": "问题插图",
        Answered: "已回答",
        Timeout: "超时",
        "Get My Score!": "获得我的分数！",
        Correct: "正确",
        Incorrect: "错误",
        "x% of others answered this question correctly over the last 3 months.": "在过去3个月中，<span class='donut-chart-percentage'>{{amount}}%</span>的人正确回答了这个问题。",
        "Click on the related bar to visit associated training material if available": "如果有，点击相关栏以访问培训材料",
        Submit: "提交",
        "Your Compliance Score (rolling 6 months)": "您的合规分数（连续6个月）",
        title: {
            infoBanner: { engagementRate: "参与比例", competencyScore: "综合能力得分", excellenceScore: "优秀分数", volumeOfQuestionsAnswered: "问题回答量" },
        },
        tooltip: {
            infoBanner: { engagementRate: "根据问题发送日期计算的已回答的比例", competencyScore: "根据回答日期计算的正确回答的比例", excellenceScore: "参与度乘以正确率" },
            chart: {
                yourScoreThisMonth: "本月的正确率以及对照组的平均分",
                yourEngagementThisMonth: "本月的参与度",
                yourExcellenceScoreThisMonth: "参与度乘以正确率",
                yourKnowledgeScoreLast3Months: "正确率按分类从高到低排序",
                yourComplianceScoreRolling6Months:
                    "过去6个月里以每个问题最后一次作答为准核算的分数。比如：一个问题，虽然花了三次才回答正确，但由于只会计算最后一次作答，所以你的分数还会是100%。",
            },
        },
        "We were not able to connect to the server, please retry the question link tomorrow.": "我们无法连接到服务器，请明天重试问题链接。",
        "question video": "问题视频",
        errorMessages: {
            questionExpired: "问题链接已过期",
            questionOpened: "问题已经开放",
            questionRemovedByAdmin: "管理员已删除此问题。",
            questionAccess: "访问问题失败",
            fileAccess: "访问文件失败",
            internalServer: "内部服务器出现意外错误",
            invalidRequest: "无效请求",
        },
        "We were not able to connect to the server. Refreshing in": "我们无法连接到服务器。在 {{leftSecond}} 内刷新",
        "CONGRATULATIONS": "恭喜你",
        "Thank you for your contribution": "感谢您的贡献",
        notification: {
            openNotifications: "查看通知",
            noData: "自上个月以来您没有通知",
            exportFileName: "通知",
            header: "通知",
            columns: {
                notificationTime: "通知时间",
                explanation: "信息"
            }
        }
    },
};

export default ch;
