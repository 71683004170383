import { SvgIcon } from "@mui/material";

const MessageIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 26 28" {...props}>
            <path
                d="M23 20.25H3V5.25H15.625C15.525 4.7625 15.4 3.8625 15.625 2.75H3C1.625 2.75 0.5 3.875 0.5 5.25V27.75L5.5 22.75H23C24.375 22.75 25.5 21.625 25.5 20.25V8.975C24.775 9.525 23.925 9.9375 23 10.125V20.25Z"
                fill="#6736F4"
            />
            <path
                d="M21.75 7.75C23.8211 7.75 25.5 6.07107 25.5 4C25.5 1.92893 23.8211 0.25 21.75 0.25C19.6789 0.25 18 1.92893 18 4C18 6.07107 19.6789 7.75 21.75 7.75Z"
                fill="#EB906B"
            />
            <path d="M15.5 15.25H5.5V17.75H15.5V15.25Z" fill="#6736F4" />
            <path d="M20.5 11.5H5.5V14H20.5V11.5Z" fill="#6736F4" />
            <path d="M5.5 10.25H20.5V10.125C18.9875 9.8125 17.6875 8.9375 16.7875 7.75H5.5V10.25Z" fill="#6736F4" />
        </SvgIcon>
    );
};

export default MessageIcon;
