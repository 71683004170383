import variables from "./variables.sass";
import colors from "./styles/colors.sass";

const common = {
    palette: {
        white: variables.white,
        nelly: variables.nelly,
        shadow2: variables.shadow2,
        gray: variables.gray,
        lottery: variables.lottery,
        lotteryBanner: variables.lotteryBanner,
        primary: {
            main: variables.nelly,
        },
        brand: {
            darkPurple1: variables.nelly,
            lightPurple: variables.lightPurple,
        },
        error: {
            main: variables.error2,
            light: variables.errorLight,
            contrast: variables.errorContrast,
        },
        success: {
            main: variables.success2,
            light: variables.successLight,
            contrast: variables.successContrast,
        },
    },
    MuiButton: {
        display: "flex",
        gap: 25,
        border: `2px solid ${variables.nelly}`,
        backgroundColor: variables.nelly,
        borderRadius: "9px",
        color: variables.white,
        textTransform: "initial",
        "& svg": {
            fontSize: 16,
        },
        "&:hover": {
            backgroundColor: variables.nellySecondary,
            border: "2px solid"
        },
    },
};

export const lightTheme = {
    mode: "light",
    palette: {
        background: variables.background,
        ...common.palette,
        error: {
            main: variables.error,
            ...common.palette.error,
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "nelly" },
                    style: {
                        ...common.MuiButton,
                    },
                },
                {
                    props: { variant: "nelly accessible" },
                    style: {
                        ...common.MuiButton,
                    },
                },
                {
                    props: { variant: "wellbeing" },
                    style: {
                        ...common.MuiButton,
                        backgroundColor: variables.wellbeingGreen,
                        border: "1px solid #5FC07A",
                        color: variables.black,
                        "&:hover": {
                            backgroundColor: variables.successLight,
                        },
                    },
                },
                {
                    props: { variant: "volvo" },
                    style: {
                        ...common.MuiButton,
                        fontFamily: "'Arial Sans', sans-serif, Source Sans Pro",
                        fontSize: 15,
                        "&:hover": {
                            backgroundColor: variables.darknavy,
                        },
                        backgroundColor: variables.darknavy,
                        fontWeight: 700,
                    },
                },
            ],
        },
        MuiTooltip: {
            defaultProps: {
                placement: "top",
            },
            styleOverrides: {
                arrow: {
                    color: variables.lighterGray,
                },
                tooltip: {
                    letterSpacing: "normal",
                    padding: "8px",
                    fontSize: "12px",
                    lineHeight: 1,
                    background: variables.lighterGray,
                    color: variables.black,
                    maxWidth: "170px",
                    textAlign: "center",
                },
            },
        },
        // HEADER
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    // "& .MuiDataGrid-columnHeader": {
                    //   backgroundColor: colors.darkPurple1,
                    //   color: "#666",
                    //   fontSize: "16px",
                    //   fontWeight: "bold",
                    // },
                    // border: "none",
                    border: "1px solid rgba(231, 231, 231, 0.5)",
                    borderRadius: "15px",
                    fontFamily: "'Open Sans', sans-serif, Arial",
                    tooltip: {
                        letterSpacing: "normal",
                        padding: "8px",
                        fontSize: "12px",
                        lineHeight: 1,
                        background: colors.darkPurple1,
                        color: colors.white,
                        maxWidth: "170px",
                        textAlign: "center",
                    },
                    // pinnedColumns: {
                    //   backgroundColor: colors.red,
                    //   boxShadow: "none",
                    // },
                    // "& .MuiDataGrid-pinnedColumns": {
                    //   border: "none",
                    //   color: colors.red,
                    //   // boxShadow: "none",
                    // },
                    "& .MuiDataGrid-columnHeader": {
                        color: colors.labelsGrey,
                        border: "none",
                        fontFamily: "'Open Sans', sans-serif, Arial",
                        fontSize: "13px",
                        "& .MuiDataGrid-focus": {
                            outline: "none",
                        },
                        "& .MuiDataGrid-within": {
                            outline: "none",
                        },
                        "& .MuiDataGrid-sortIcon": {
                            color: colors.nellyPurple,
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            padding: 2,
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            // fontWeight: 400,
                            whitespace: "normal",
                            wordwrap: "breakword",
                        },
                        // "& .MuiDataGrid-iconSeparator": {
                        //   display: "none",
                        // },
                    },
                    "& .MuiDataGrid-colCell": {
                        border: "none",
                    },
                    // "& .MuiDataGrid-colCellTitleContainer": {
                    //   whitespace: "normal",
                    //   wordwrap: "breakword",
                    // },
                    "& .MuiDataGrid-row": {
                        backgroundColor: "white",
                        border: "1px solid rgba(231, 231, 231, 0.5)",
                        borderRadius: "15px",
                        // marginBottom: "0.5rem",
                    },
                    "& .MuiDataGrid-cell": {
                        border: "none",
                        focus: {
                            outline: "none",
                            within: {
                                outline: "none",
                            },
                        },
                    },
                    "& .MuiDataGrid-cellContent": {
                        border: "none",
                        fontFamily: "'Open Sans', sans-serif, Arial",
                        fontSize: "14px",
                    },
                },
            },
        },

        // PAGINATION
        "& .MuiDataGrid-MuiTablePagination": {
            styleOverrides: {
                selectLabel: {
                    fontSize: 12,
                },
                select: {
                    fontSize: 12,
                },
                displayedRows: {
                    fontSize: 12,
                },
            },
        },
    },
};

export const getDesignTokens = (mode) => {
    // if (mode === "dark") {
    //     return darkTheme;
    // } else
    // if (mode === "accessibility") {
    return lightTheme;
    // } else {
    //     return lightTheme;
    // }
};
