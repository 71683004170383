import { Box, useMediaQuery, CircularProgress, Grid } from "@mui/material";
import { Outlet, useSearchParams } from "react-router-dom";
import Main from "./Main";
import NavbarQA from "./NavbarQA";
import SidebarQA from "./SidebarQA";
import "./DashboardQA.sass";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../functions/auth";
import { setDashboardChart } from "./DashboardChartSlice";
import { setData } from "../common/infoBanner/InfoBannerSlice";
import { setTrackingCode } from "../common/questionAnswer/QuestionAnswerSlice";
import { setPendingQuestions } from "../common/questionAnswer/PendingQuestionSlice";
import { setConfigurationData, setPreviewMode, setIsConfigDataReady, updateLogo } from "./DashboardInfoQASlice";
import i18n from "../../internationalization/i18n";
import Cookies from "universal-cookie";
import { fetchData, endPoints } from "../common/questionAnswer/DataProvider";
import ErrorHandler from "../common/fallback/ErrorHandler";
import { withTranslation } from "react-i18next";
import { updateNotificationCounts } from "../common/notification/NotificationSlice";
import { setBadgeData } from "../navbar/LauralBadgeSlice";

const DashboardQA = ({ t }) => {
    return <ErrorHandler baseComponent={<DashboardQAUI t={t} />} t={t} />;
};
const DashboardQAUI = ({ t }) => {
    const [open, setOpen] = useState(true);
    const isDesktop = useMediaQuery("(min-width:900px)");
    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const toggleOpen = (value) => {
        setOpen(value);
    };

    const { isSso, company, authorization } = useAuth();

    const [params] = useSearchParams();
    let previewMode = !!params.get("r");
    const isDataReady = useSelector((state) => state.dashboardInfoQA?.isConfigDataReady);

    useEffect(() => {
        let newTrackingCode = params.get("t") || params.get("r");

        if (!newTrackingCode) {
            throw Error(t("Invalid Link"));
        } else if (!!newTrackingCode && newTrackingCode !== trackingCode) {
            dispatch(setIsConfigDataReady(false));
            dispatch(setTrackingCode(newTrackingCode));
            if (previewMode) {
                dispatch(setPreviewMode(previewMode));
                dispatch(setIsConfigDataReady(true));
            } else {
                refreshDashboardData(newTrackingCode);
            }
        }
    }, [params]);

    const refreshDashboardData = (newTrackingCode) => {
        refreshConfiguration(newTrackingCode);
        refreshInfoBannerData(newTrackingCode);
        refreshPendingQuestionsInfo(newTrackingCode);
        refreshUnreadNotificationQty(newTrackingCode);
        refreshPerformanceData(newTrackingCode); // it is expected empty answer for survey questions
        getBadgeData(newTrackingCode);
    };

    const updatePendingQuestions = (response) => {
        response.path = (isSso ? "/sso-" + company : "") + "/question?pending=true&t=";
        dispatch(setPendingQuestions(response));
    };

    const refreshPendingQuestionsInfo = (trackingCode) => {
        let endpoint = endPoints.PendingQuestions + "?q=" + trackingCode;
        fetchData(endpoint, authorization, undefined, undefined, updatePendingQuestions, undefined, trackingCode);
    };

    const updateUnreadNotifications = (response) => {
        dispatch(updateNotificationCounts(response));
    };
    const handleNotGettingNotificationQty = () => {
        dispatch(updateNotificationCounts(0));
    };
    const refreshUnreadNotificationQty = (trackingCode) => {
        let endpoint = endPoints.UserNotification + "?t=" + trackingCode + "&qty=true";
        fetchData(endpoint, authorization, undefined, undefined, updateUnreadNotifications, handleNotGettingNotificationQty, trackingCode);
    };

    const updateDashboardChart = (response) => {
        if (
            !!response &&
            Array.isArray(response) &&
            response?.filter((el) => typeof el?.value === "number")?.length === 2 &&
            response.filter((el) => !!el && el?.value > 0)?.length > 0
        ) {
            dispatch(setDashboardChart(response));
        } else {
            dispatch(setDashboardChart([]));
        }
    };
    const refreshPerformanceData = (trackingCode) => {
        let endpoint = endPoints.PerformanceData + "?q=" + trackingCode;
        fetchData(endpoint, authorization, undefined, undefined, updateDashboardChart, updateDashboardChart, trackingCode);
    };

    const updateBadgeData = (response) => {
        if (response && typeof response == "object" && response.comp_limit && response.eng_limit) {
            dispatch(setBadgeData(response));
        } else {
            dispatch(setBadgeData(undefined));
        }
    };
    const getBadgeData = (trackingCode) => {
        let endpoint = endPoints.BadgeData + "?q=" + trackingCode;
        fetchData(endpoint, authorization, undefined, undefined, updateBadgeData, updateBadgeData, trackingCode);
    };
    const updateInfoBannerData = (response) => {
        dispatch(setData(Object.values(response)));
    };
    const refreshInfoBannerData = (tracking) => {
        const formData = new URLSearchParams();
        formData.append("q", tracking);
        let endpoint = endPoints.Infobanner;
        fetchData(endpoint, authorization, "application/x-www-form-urlencoded", formData, updateInfoBannerData, undefined, tracking);
    };
    const updateConfigurationData = (response, trackingCode) => {
        if (params.get("pending")) {
            //come from by clicking pending question
            response.displayButtonToAnswer = false;
            response.setbuttonToAnswerCookies = false;
        } else {
            response.displayButtonToAnswer = response?.buttonToAnswerEnabled == "2" || (response?.buttonToAnswerEnabled == "1" && !cookies.get("userPressedButton"));
            response.setbuttonToAnswerCookies = response?.buttonToAnswerEnabled == "1";
        }
        if (response.language) {
            i18n.changeLanguage(response?.language);
        }
        if (response.hideSidebarOnDefault || !isDesktop) {
            setOpen(!response?.hideSidebarOnDefault && isDesktop);
        }

        dispatch(setConfigurationData(response));
        requestLogo(trackingCode);
    };
    const handleNotGettingConfiguration = (error, trackingCode) => {
        let defaultconfig = {};
        defaultconfig.buttonToAnswerEnabled = "0";
        defaultconfig.hideSidebarOnDefault = false;
        defaultconfig.scoreButtonEnabled = true;
        updateConfigurationData(defaultconfig, trackingCode);
    };
    const refreshConfiguration = (trackingCode) => {
        let endpoint = endPoints.Configuration + "?q=" + trackingCode;
        fetchData(endpoint, authorization, undefined, undefined, updateConfigurationData, handleNotGettingConfiguration, trackingCode);
    };
    const updateLogoData = (response) => {
        dispatch(updateLogo(response));
    };

    const handleNotGettingLogo = () => {
        dispatch(updateLogo(undefined));
    };
    const requestLogo = (trackingCode) => {
        let endpoint = endPoints.Logo + "?q=" + trackingCode;
        fetchData(endpoint, authorization, undefined, undefined, updateLogoData, handleNotGettingLogo, trackingCode);
    };
    return (
        <Box id="qadashboard">
            {isDataReady ? (
                <>
                    <ErrorHandler baseComponent={<SidebarQA open={open} isDesktop={isDesktop} />} />
                    <Main>
                        <ErrorHandler baseComponent={<NavbarQA open={open} handleOpen={(o) => toggleOpen(o)} />} />
                        <div id="qamain-content">
                            <ErrorHandler baseComponent={<Outlet />} />
                        </div>
                    </Main>
                </>
            ) : (
                <Grid container className="loading">
                    <Grid item className="loading-box">
                        <CircularProgress color="primary" />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default withTranslation()(DashboardQA);
