import { createSlice } from "@reduxjs/toolkit";

const TimeoutSliceQA = createSlice({
    name: "timeoutQAstate",
    initialState: {
        isTimeout: false
    },
    reducers: {

        setIsTimeout: (state, { payload }) => {
            state.isTimeout = payload;
        },
    },
});

export const { setIsTimeout } = TimeoutSliceQA.actions;
export default TimeoutSliceQA.reducer;
