import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { withTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "95%",
    maxHeight: "95vh",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    bgcolor: "transparent",
    border: "none",
    boxShadow: 0,
};

const QuestionImage = ({ t, question }) => {
    const [previewOpen, setPreviewOpen] = useState(false);

    return (
        <>
            <div tabIndex={10}>
                <img
                    data-testid="question-image"
                    className="question-image"
                    src={question.image}
                    onClick={() => setPreviewOpen(true)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' || event.key === 'Space') {
                            setPreviewOpen(true);
                        }
                    }}
                    aria-labelledby="question-image-label"
                    alt={question.imageText || t("question illustration")}
                />
                <p data-testid="question-image-label" id="question-image-label">
                    {question.imageText || t("question illustration")}
                </p>
            </div>
            <Modal open={previewOpen} onClose={() => setPreviewOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} onClick={() => setPreviewOpen(false)}>
                    <img
                        data-testid="question-image-maximised"
                        src={question.image}
                        aria-labelledby="question-image-label"
                        alt={question.imageText || t("question illustration")}
                        width="100%"
                        height="auto"
                    />
                </Box>
            </Modal>
        </>
    );
};

export default withTranslation()(QuestionImage);
