import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import i18n from "../../internationalization/i18n";
import { HighContrastBlackTheme } from "./themes/HighContrastBlackTheme";
import { HighContrastWhiteTheme } from "./themes/HighContrastWhiteTheme";

const BarAndDot = ({ data, chartId, handleClick, colorThemeMui }) => {
    if (!data || data.length === 0 || !chartId) {
        return { root: undefined, chart: undefined };
    }

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    const root = am5.Root.new(chartId);

    const themes = [am5themes_Animated.new(root)];

    switch (colorThemeMui) {
        case "high-contrast-black":
            themes.push(HighContrastBlackTheme.new());
            break;
        case "high-contrast-white":
            themes.push(HighContrastWhiteTheme.new());
            break;
    }

    root.setThemes(themes);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: false,
            wheelY: false,
            layout: root.verticalLayout,
        })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    const yAxisRenderer = am5xy.AxisRendererY.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 0,
    });
    const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: "category",
            renderer: yAxisRenderer,
        })
    );

    chart.onPrivate("width", function (width) {
        yAxisRenderer.labels.template.setAll({
            maxWidth: width - 75,
            oversizedBehavior: "wrap",
            breakWords: true,
            textAlign: "right",
        });
    });

    yAxis.data.setAll(data);

    const xAxisRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 50,
    });

    xAxisRenderer.ticks.template.setAll({
        visible: false,
    });

    const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            max: 100,
            renderer: xAxisRenderer,
        })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: i18n.t("Performance"),
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "score",
            categoryYField: "category",
            fill: data[0].color,
            sequencedInterpolation: true,
            interpolationDuration: 1000,
            interpolationEasing: am5.ease.inOut(am5.ease.elastic),
            tooltip: am5.Tooltip.new(root, {
                keepTargetHover: true,
                ariaLabel: `{category}: {score}%`,
                role: "tooltip",
            }),
        })
    );

    series1.columns.template.setAll({
        height: am5.percent(80),
        tooltipText: "{categoryY}: {valueX}%",
    });

    chart.get("colors").set(
        "colors",
        data.map((el) => {
            return el.color;
        })
    );

    series1.columns.template.adapters.add("fill", function (_fill, target) {
        return chart.get("colors").getIndex(series1.columns.indexOf(target));
    });

    series1.columns.template.adapters.add("stroke", function (_stroke, target) {
        return chart.get("colors").getIndex(series1.columns.indexOf(target));
    });

    series1.columns.template.events.on(
        "click",
        function (ev) {
            const trainingLink = ev.target.dataItem.dataContext.trainingLink;
            if (trainingLink) {
                window.open(trainingLink, "_blank", "noreferrer");
            } else {
                const catId = ev.target.dataItem.dataContext.catId;
                handleClick(catId);
            }
        },
        this
    );

    series1.bullets.push(function () {
        return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
                radius: 1,
                fill: data[0].color,
                focusable: true,
                hoverOnFocus: true,
                tooltipText: "{categoryY}: {valueX}%",
                ariaLabel: "{categoryY} {valueX}%",
                role: "tooltip",
            }),
        });
    });

    series1.data.setAll(data);
    series1.appear();

    if (data.filter((el) => el.peerGroup).length > 0) {
        const series2 = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Peer Group",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "peerGroup",
                categoryYField: "category",
                sequencedInterpolation: true,
                stroke: "#00000000",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryY}: {valueX}",
                    ariaLabel: "{name} - {categoryY}: {valueX}",
                    role: "tooltip",
                }),
            })
        );

        series2.strokes.template.setAll({
            strokeWidth: 2,
        });

        series2.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0.5,
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    stroke: series2.get("stroke"),
                    strokeWidth: 2,
                    fill: "#6736F4",
                }),
            });
        });
        series2.data.setAll(data);
        series2.appear();

        series2.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 1,
                    fill: "transparent",
                    focusable: true,
                    ariaLabel: "{name} {categoryY} {valueX}%",
                    role: "figure",
                }),
            });
        });
    }

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.children.push(
        am5.Legend.new(root, {
            centerX: am5.percent(0),
            x: am5.percent(0),
        })
    );

    legend.data.setAll(
        chart.series.values
        /* if we are to add blue dots to the right end side --> .filter((el, idx) => idx !== 1)*/
    );

    chart.appear(1000, 100);

    return { root, chart };
};
export default BarAndDot;
