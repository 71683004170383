import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import humanize from "../../functions/humanize";
import { LineChartColorTheme } from "./themes/LineChartColorTheme";
import i18n from "../../internationalization/i18n";
import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import am5locales_tr_TR from "@amcharts/amcharts5/locales/tr_TR";
import am5locales_fr_FR from "@amcharts/amcharts5/locales/fr_FR";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5locales_ja_JP from "@amcharts/amcharts5/locales/ja_JP";
import am5locales_ko_KR from "@amcharts/amcharts5/locales/ko_KR";
import am5locales_pt_PT from "@amcharts/amcharts5/locales/pt_PT";
import { HighContrastBlackTheme } from "./themes/HighContrastBlackTheme";
import { HighContrastWhiteTheme } from "./themes/HighContrastWhiteTheme";

const LineChart = ({ data, chartId, colorThemeMui }) => {
    if (!data || data.length === 0 || !chartId) {
        return { root: undefined, chart: undefined };
    }

    let root = am5.Root.new(chartId);

    // TODO to keep dates up to date with language options, update here for every new options LOCALE_AMCHARTS_DATES
    if (i18n.language === "tr") {
        root.locale = am5locales_tr_TR;
    } else if (i18n.language === "fr" || i18n.language === "frca") {
        root.locale = am5locales_fr_FR;
    } else if (i18n.language === "es") {
        root.locale = am5locales_es_ES;
    } else if (i18n.language === "ja") {
        root.locale = am5locales_ja_JP;
    } else if (i18n.language === "ko") {
        root.locale = am5locales_ko_KR;
    } else if (i18n.language === "pt") {
        root.locale = am5locales_pt_PT;
    } else {
        root.locale = am5locales_en_US;
    }

    const responsive = am5themes_Responsive.new(root);
    const themes = [responsive, am5themes_Animated.new(root)];
    switch (colorThemeMui) {
        case "high-contrast-black":
            themes.push(HighContrastBlackTheme.new());
            break;
        case "high-contrast-white":
            themes.push(HighContrastWhiteTheme.new());
            break;
    }
    themes.push(LineChartColorTheme.new());

    root.setThemes(themes);
    root.tabindex = 0;

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            zoomY: false,
            zoomX: false,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            focusable: false,
        })
    );

    const yAxisRenderer = am5xy.AxisRendererY.new(root, {
        inside: true,
        minGridDistance: 30, //Guarantee resize scale not to change on this axis
    });

    const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            min: 0,
            max: 100,
            renderer: yAxisRenderer,
        })
    );
    //styling Y labels
    yAxisRenderer.labels.template.setAll({
        layer: 2,
        centerY: am5.p0,
    });

    let rangeDataItem = yAxis.makeDataItem({
        value: 80,
        endValue: 100,
    });
    chart.topAxesContainer.children.push(
        am5.Label.new(root, {
            text: "",
            width: 12,
            height: 12,
            y: am5.percent(10),
            background: am5.RoundedRectangle.new(root, {
                fill: am5.color("#e7e6e6"),
                fillOpacity: 1,
                cornerRadiusTL: 0,
                cornerRadiusTR: 0,
                cornerRadiusBL: 0,
                cornerRadiusBR: 0,
                stroke: am5.color("#b3b3b3"),
            }),
        })
    );
    let highPerformanceLabel;
    highPerformanceLabel = chart.topAxesContainer.children.push(am5.Container.new(root, { width: am5.p100 })).children.push(
        am5.Label.new(root, {
            text: i18n.t("High Performance Zone"),
            x: am5.percent(1),
            y: am5.percent(0),
            centerY: am5.percent(10),
        })
    );

    highPerformanceLabel.setAll({
        oversizedBehavior: "wrap",
        breakWords: true,
    });

    chart.topAxesContainer.set("height", 75);

    yAxis.createAxisRange(rangeDataItem);
    rangeDataItem.get("grid").set("visible", false);
    rangeDataItem.get("axisFill").setAll({
        fill: am5.color(0xe7e6e6),
        fillOpacity: 0.9,
        visible: true,
    });

    let legend;
    chart.topAxesContainer.set("layout", root.horizontalLayout);
    legend = chart.topAxesContainer.children.push(am5.Container.new(root, { width: am5.p100 })).children.push(
        am5.Legend.new(root, {
            useDefaultMarker: true,
        })
    );

    legend.valueLabels.template.set("forceHidden", true);

    legend.labels.template.setAll({
        oversizedBehavior: "wrap",
        breakWords: true,
    });

    chart.onPrivate("width", function (width) {
        legend.labels.template.setAll({
            maxWidth: width / 2,
            paddingRight: 35,
        });
        highPerformanceLabel.setAll({
            maxWidth: width / 2,
        });
    });

    legend.markers.template.setAll({
        width: 12,
        height: 12,
    });
    legend.markerRectangles.template.setAll({
        cornerRadiusTL: 20,
        cornerRadiusTR: 20,
        cornerRadiusBL: 20,
        cornerRadiusBR: 20,
    });

    const xAxisRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 10, //Guarantee resize scale not to change on this axis
    });
    const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
            extraMin: 0.02, //span space for Xaxis to not collide with Y
            extraMax: 0.02,
            baseInterval: {
                timeUnit: "month",
                count: 1,
            },
            periodChangeDateFormats: {
                month: "MMM/yy",
            },
            renderer: xAxisRenderer,
        })
    );

    xAxisRenderer.labels.template.setAll({
        oversizedBehavior: "truncate",
    });

    xAxisRenderer.grid.template.setAll({
        strokeOpacity: 0,
    });

    // Set date fields
    // https://www.amcharts.com/docs/v5/concepts/data/#Parsing_dates
    root.dateFormatter.setAll({
        dateFormat: "MMM",
        dateFields: ["valueX"],
    });

    const colorSet = am5.ColorSet.new(root, {});

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    Object.keys(data[0]).forEach((element, index) => {
        if (element.toString() !== "date") {
            let currentSeries = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: i18n.t(humanize(element.toString())),
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: element.toString(),
                    valueXField: "date",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: `[fontSize: 12px]{name} - {valueX}: [bold, fontSize: 12px]{valueY}%[/][/]`,
                        ariaLabel: `{name} - {valueX}: {valueY}%`,
                        role: "tooltip",
                    }),
                })
            );

            currentSeries.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        radius: 0,
                        fill: colorSet.getIndex(index),
                        focusable: true,
                        hoverOnFocus: true,
                        tooltipText: "{name} - {valueX}: {valueY}%",
                        ariaLabel: "{name} - {valueX}: {valueY}%",
                        role: "figure",
                    }),
                });
            });

            //line thickness
            currentSeries.strokes.template.setAll({
                strokeWidth: 2,
            });

            //push data and legend
            currentSeries.data.setAll(data);
        }
    })
    legend.data.setAll(chart.series.values);

    chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
        })
    );

    chart.appear(0);

    return { root, chart };
};

export default LineChart;
