import { createSlice } from "@reduxjs/toolkit";

const initState = {
    data: [],
};

const PerformanceSlice = createSlice({
    name: "performance",
    initialState: initState,
    reducers: {
        setPerformanceData: (state, { payload }) => {
            state.data = payload;
        },
    },
});

export const { setPerformanceData } = PerformanceSlice.actions;
export default PerformanceSlice.reducer;
