import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "light",
    colorThemeMui: "",
};

const ColorsSwitcherSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setThemeMode: (state, { payload }) => {
            state.mode = payload;
        },
        setColorThemeMui: (state, { payload }) => {
            state.colorThemeMui = payload;
        },
    },
});

export const { setThemeMode, setColorThemeMui } = ColorsSwitcherSlice.actions;
export default ColorsSwitcherSlice.reducer;
