import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DonutChart from "../charts/DonutChart";
import "./OthersInfo.sass";
import { useEffect } from "react";

const OthersInfoQA = ({ t }) => {
    const dashboardChart = JSON.parse(JSON.stringify(useSelector((state) => state.dashboardChart))).sort((a, b) => b.dataType - a.dataType);

    let sum = 0;
    let correctPercent = 0;
    if (!!dashboardChart && dashboardChart.length > 0) {
        if (dashboardChart?.find((el) => el.dataType === "Incorrect").value === 0) {
            correctPercent = 100;
        } else {
            dashboardChart.forEach((item) => {
                sum += item.value;
            });
            correctPercent = Math.round((100 * dashboardChart?.find((el) => el.dataType === "Correct")?.value) / sum);
        }
        dashboardChart.forEach((item) => {
            item.dataType = t(item.dataType);
        });
    }

    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);
    useEffect(() => {
        if (customStyle === "nelly accessible" && document?.getElementsByClassName("donut-chart-percentage")) {
            document?.getElementsByClassName("donut-chart-percentage")[0]?.setAttribute("class", "donut-chart-percentage accessible");
        }
    }, [customStyle, document?.getElementsByClassName("donut-chart-percentage")])

    return (
        <>
            {!!dashboardChart && dashboardChart.length > 0 && dashboardChart.filter((el) => el?.value > 0).length > 0 && (
                <>
                    <p
                        tabIndex={0}
                        className="donut-chart-description"
                        data-testid="DonutAnswerChart"
                        dangerouslySetInnerHTML={{
                            __html: t("x% of others answered this question correctly over the last 3 months.", { amount: correctPercent }),
                        }}
                    ></p>
                    <DonutChart divId="othersDataDonutChart" data={dashboardChart} />
                </>
            )}
        </>
    );
};

export default withTranslation()(OthersInfoQA);
