import { withTranslation } from "react-i18next";

const QuestionVideo = ({ t, question }) => {
    return (
        <div tabIndex={10}>
            <video controls controlsList="nodownload" width="100%" style={{ maxHeight: 300 }} data-testid="question-video">
                <source className="question-video" src={"data:video/mp4;base64," + question.videoFile} />
                Sorry, your browser doesn't support videos.
            </video>
            <p data-testid="question-video-label" id="question-video-label">
                {question?.videoText || t("question video")}
            </p>
        </div>
    );
};

export default withTranslation()(QuestionVideo);
