import React, { createContext, useState } from "react";

const DataTableContext = createContext();

const DataTableAPIProvider = ({ children }) => {
    const [dataTableApi, setDataTableApiRef] = useState();
    const [fileName, setFileName] = useState("");

    return <DataTableContext.Provider value={{ dataTableApi, setDataTableApiRef, fileName, setFileName }}>{children}</DataTableContext.Provider>;
};

export { DataTableContext, DataTableAPIProvider };
