import {Box, Tooltip, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import "./InfoSection.sass";
import TendencyDisplay from "./TendencyDisplay";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

const InfoSection = ({t, data}) => {
    const {title, score, unit, helpToolTip} = data;

    return (
        <Box className="info-section">
            <Typography tabIndex={0} className="title" aria-label={t(title)}>
                {t(title)}
                {!!helpToolTip && (
                    <Tooltip title={t(helpToolTip)} arrow placement="top">
                        <HelpOutlineRoundedIcon
                            tabIndex={0}
                            aria-hidden={false}
                            aria-label={t(helpToolTip)}
                            className="tooltipIcon"
                        />
                    </Tooltip>
                )}
            </Typography>
            <div className="score-container">
                <p tabIndex={0} className="score" aria-label={score + (unit ?? "")}>{score + (unit ?? "")}</p>
                <TendencyDisplay {...data} />
            </div>
        </Box>
    );
};

export default withTranslation()(InfoSection);
