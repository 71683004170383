import { SvgIcon } from "@mui/material";

const LightbulbIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 30 30" {...props}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.33333 26.6667H12.6667C12.6667 28.1333 11.4667 29.3333 10 29.3333C8.53333 29.3333 7.33333 28.1333 7.33333 26.6667ZM4.66667 25.3333H15.3333V22.6667H4.66667V25.3333ZM20 12.6667C20 17.76 16.4533 20.48 14.9733 21.3333H5.02667C3.54667 20.48 0 17.76 0 12.6667C0 7.14667 4.48 2.66667 10 2.66667C15.52 2.66667 20 7.14667 20 12.6667ZM17.3333 12.6667C17.3333 8.62667 14.04 5.33333 10 5.33333C5.96 5.33333 2.66667 8.62667 2.66667 12.6667C2.66667 15.96 4.65333 17.8533 5.8 18.6667H14.2C15.3467 17.8533 17.3333 15.96 17.3333 12.6667ZM26.4933 9.82667L24.6667 10.6667L26.4933 11.5067L27.3333 13.3333L28.1733 11.5067L30 10.6667L28.1733 9.82667L27.3333 8L26.4933 9.82667ZM23.3333 8L24.5867 5.25333L27.3333 4L24.5867 2.74667L23.3333 0L22.08 2.74667L19.3333 4L22.08 5.25333L23.3333 8Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default LightbulbIcon;
