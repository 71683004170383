import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en-US";
import tr from "./tr-TR";
import fr from "./fr-FR";
import frca from "./fr-CA";
import ch from "./ch-CH";
import es from "./es-ES";
import ja from "./ja-JP";
import ko from "./ko-KR";
import pt from "./pt-PT";
import { SUPPORTED_LANGUAGES } from "../constants";

i18n.on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng);
    document.documentElement.setAttribute("dir", i18n.dir());
    //document.documentElement.setAttribute('dir', 'rtl')
});

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: SUPPORTED_LANGUAGES,
        resources: {
            en: en,
            /* // TODO for every new language added here,
             ** // TODO in LineChart.js file there needs to be updated to keep months translated according to the locale
             ** // TODO LOCALE_AMCHARTS_DATES can be searched throughout the project
             */ //
            tr: tr,
            fr: fr,
            ch: ch,
            es: es,
            ja: ja,
            ko: ko,
            pt: pt,
            frca: frca,
        },
        fallbackLng: "en",
        // debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
