// !!! KEYS AND VALUES BOTH OF THEM MUST STAY UNIQUE !!!
export const ERROR_CODES = {
    1001: "Invalid Tracking Code",
    1002: "Invalid code",
    1003: "Invalid User",
    1004: "Invalid Tracking Post Request",
    1005: "Invalid Request",
    1006: "Tracking not found",
    1007: "Invalid Lottery",
    2001: "Video streaming file not found",
    2002: "Video streaming failed",
    2101: "File access failed",
    3001: "User not authorized",
    3002: "Crypto response error",
    3003: "Ip is not in range with the db values",
    4001: "Question link expired",
    4002: "Question is already opened",
    4003: "This question has been removed by the administrator",
    5001: "An unexpected internal server error occurred",
};

export const findErrorCode = (tmpMessage) => {
    return Object.entries(ERROR_CODES).filter((el) => {
        return tmpMessage.includes(el[1].toLowerCase());
    })?.[0]?.[0] || 9998;
}
