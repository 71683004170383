import { Box, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./Fallback.sass";
import { useSelector } from "react-redux";
import { ERROR_CODES, findErrorCode } from "./ErrorCodes";
import { ScoreButton } from "../questionAnswer/ScoreButton";
import { getScore } from "../questionAnswer/QuestionAnswer";
const FallbackQA = ({ t, errorInfo, display }) => {
    let message;
    const location = useLocation();
    const isSso = location.pathname.startsWith("/sso");
    const company = isSso && location.pathname.split("/")[1]?.split("-")[1];
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);
    const userFullName = useSelector((state) => state.dashboardInfoQA?.fullName);
    let previewMode = useSelector((state) => state.dashboardInfoQA?.previewMode)
    let scoreButtonEnabled = useSelector((state) => state.dashboardInfoQA?.scoreButtonEnabled)
    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);
    let subMessage = "";
    const nellySupportEmailAddress = useSelector((state) => state.dashboardInfoQA?.nellySupportEmailAddress);
    const sendMessageEmailAddress = nellySupportEmailAddress || process.env.REACT_APP_ERROR_SUPPORT_EMAIL_ADDRESS;
    const sendErrorMessageToEmail = t("errorMessages.sendMessageToEmail", { email: sendMessageEmailAddress });
    const sendErrorMessageToNellySupport = t("errorMessages.sendMessageToSupport");

    const prepareMessage = (errorInfo) => {
        if (!errorInfo?.errorMsg && !errorInfo?.errorCode) {
            message = (errorInfo?.customMessage ? errorInfo?.customMessage : t("errorMessages.unexpectedError")) + ": " + 9999;
            subMessage = sendErrorMessageToNellySupport;
        } else if (errorInfo?.errorCode) {
            message = t("errorMessages.dataCannotBeDisplayed") + ": " + errorInfo?.errorCode
        } else {
            extractBackEndErrorMessage();
        }
    }
    const extractBackEndErrorMessage = () => {
        let tmpMessage = errorInfo?.errorMsg.toLowerCase() || "";
        let errorCode = findErrorCode(tmpMessage);
        if (tmpMessage.includes("failed to fetch") || tmpMessage.includes("network")) {
            message = t("errorMessages.networkError");
        }
        else if (tmpMessage.includes("tracking not found")) {
            message = t("errorMessages.noQuestionFound");
        } else if (tmpMessage.includes("question")) {
            extractQuestionMessage(tmpMessage, errorCode);
        } else if (tmpMessage.includes("file") || tmpMessage.includes("video")) {
            message = t("errorMessages.fileAccess") + ": " + errorCode;
            subMessage = sendErrorMessageToNellySupport;
        }
        else if (tmpMessage.includes("not authorized") || tmpMessage.includes("crypto") || tmpMessage.includes("ip is not in range")) {
            message = t("errorMessages.accessDenied") + ": " + errorCode;
        } else {
            message = (errorInfo?.customMessage ? errorInfo?.customMessage : t("errorMessages.unexpectedError")) + ": " + errorCode;
            subMessage = sendErrorMessageToNellySupport;
        }
    }

    const extractQuestionMessage = (tmpMessage, errorCode) => {
        if (tmpMessage.includes("expired")) {
            message = t("errorMessages.questionExpired") + ": " + errorCode;
        } else if (tmpMessage.includes("opened")) {
            message = t("errorMessages.questionOpened") + ": " + errorCode;
        } else if (tmpMessage.includes("removed") && tmpMessage.includes("admin")) {
            message = t("errorMessages.questionRemoved") + ": " + errorCode;
        } else {
            message = t("errorMessages.questionAccess") + ": " + errorCode;
            subMessage = sendErrorMessageToEmail;
        }
    }

    prepareMessage(errorInfo);
    return (
        <Box data-testid="FallBack" className="error-container">
            {!!message && (
                <>
                    <h2 tabIndex={1} data-testid="fallbackmessage" className="error main-message">{message}</h2>
                    <h3 tabIndex={1} data-testid="fallbacksubmessage" className="error sub-message">{subMessage}</h3>
                    <h4 tabIndex={1} data-testid="fallbacktime" className="error timestamp">{new Date().toUTCString()}</h4>
                    <Grid item className="grid-item-button" sx={(previewMode || !scoreButtonEnabled || !userFullName) ? { display: 'none' } : {}}>
                        <ScoreButton data-testid="fallbackscorebtn" t={t} customStyle={customStyle} buttonAction={() => getScore(isSso, company, trackingCode)} />
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default withTranslation()(FallbackQA);
