import { createSlice } from "@reduxjs/toolkit";

const ComplianceSlice = createSlice({
    name: "compliance",
    initialState: {
        complianceScoreData: [],
    },
    reducers: {
        setComplianceScoreData: (state, { payload }) => {
            state.complianceScoreData = payload;
        },
    },
});

export const { setComplianceScoreData } = ComplianceSlice.actions;
export default ComplianceSlice.reducer;
