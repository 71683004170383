
import { AppBar, Box, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import "./TimeoutQA.sass";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowHideTimer } from "../../base/DashboardInfoQASlice";
import variables from "../../../variables.sass";
import { setIsTimeout } from "./TimeoutSliceQA";


const TimeoutQA = ({ t, max, trackingCode, timerStartTime, setTimerStartTime }) => {
    const [timeLeft, setTimeLeft] = useState(max);
    const dispatch = useDispatch();
    const countDownVal = max;
    const hideTimer = useSelector((state) => state.dashboardInfoQA?.hideTimer == "true");
    const hideTimerButtonEnabled = useSelector((state) => state.dashboardInfoQA?.hideTimerButtonEnabled);
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);

    useEffect(() => {
        setTimerStartTime(Date.now())
        // console.log("TimerStartTime : ", timerStartTime);
    }, []);

    useEffect(() => {
        if (countDownVal != 0) {
            const interval = setInterval(() => {

                const elapsedTime = Math.floor((Date.now() - timerStartTime) / 1000);
                const ltimeLeft = countDownVal - elapsedTime;
                if (ltimeLeft < 0) {
                    clearInterval(interval);
                    setTimeLeft(0);
                    dispatch(setIsTimeout(true));
                } else {
                    setTimeLeft(ltimeLeft);
                }
            }, 1000);

            return () => clearInterval(interval); // Cleanup on unmount
        }
    }, [timerStartTime, countDownVal, dispatch]);


    const showHideTimer = (val, tracking) => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/userData/hideTimer?t=${tracking}&hideTimer=${val.toString()}`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
        }).then(async (r) => {
        });
        dispatch(setShowHideTimer(val.toString()));
    };

    const getBackgroundColor = () => {
        if (customStyle === "volvo") { //todo move it saas
            return timeLeft === 0 ? variables.volvoerror : variables.volvosuccess;
        } else if (customStyle === "nelly accessible") {
            return timeLeft === 0 ? variables.accessibleError : variables.darkerSuccessForAccessibility;
        }
        return timeLeft === 0 ? variables.error : variables.success;
    };

    if (!countDownVal || countDownVal == 0)
        return <></>
    return (
        <Box className="timer-container">
            {
                (
                    <>
                        <Grid container className="time-remaining-area" style={{ color: getBackgroundColor(), padding: "5px" }}>
                            <Grid item xs={8} className={`time-remaining ${customStyle}`}>
                                {t("Time remaining...")}
                            </Grid>
                            <Grid item xs={4} className={`show-hide-timer-area ${customStyle}`}>
                                {hideTimerButtonEnabled && (
                                    <div
                                        className="show-hide-timer-btn-desktop  ${customStyle}"
                                        tabIndex={40}
                                        onKeyDown={(e) => {
                                            if (e.key === " " || e.key === "Enter") {
                                                e.currentTarget.click();
                                            }
                                        }}
                                        onClick={() => {
                                            showHideTimer(!hideTimer, trackingCode);
                                        }}
                                    >
                                        <div className={`show-timer text hidden-${!!hideTimer}`}>{t("Show timer")}</div>
                                        <div className={`hide-timer text hidden-${!!hideTimer}`}>{t("Hide timer")}</div>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                        <AppBar
                            id="qatimeout-desktop-bar"
                            component="div"
                            data-testid="timeout-desktop-bar"
                            position="relative"
                            tabIndex={41}
                            sx={(theme) => {
                                return {
                                    transition: theme.transitions.create(["width"], { easing: "linear", duration: 300 }),
                                    width: !hideTimer ? "100%" : 0,
                                };
                            }}
                        >
                            <Box
                                className="timeout-desktop-bar-container"
                                sx={(theme) => {
                                    //transition 1000 to make smoother for "seconds" timeout
                                    return {
                                        transition: theme.transitions.create(["background-color"], { easing: "linear", duration: 1000 }),
                                        backgroundColor: getBackgroundColor(),
                                    };
                                }}
                            />
                            <Box
                                className={`timeout-toolbar  ${customStyle}`}
                                sx={(theme) => {
                                    //transition 1000 to make smoother for "seconds" timeout
                                    return {
                                        display: "flex",
                                        transition: theme.transitions.create(["width", "transform", "background-color", "border-radius"], {
                                            easing: "linear",
                                            duration: 1000
                                        }),
                                        boxSizing: "border-box",
                                        textAlign: "end",
                                        fontWeight: 500,
                                        width: `calc((${timeLeft} * 100% / ${max}))`,
                                        backgroundColor: getBackgroundColor(),
                                    };
                                }}
                            >
                                <span data-testid="time-left">{timeLeft}</span>
                            </Box>
                        </AppBar>
                    </>
                )}
        </Box>
    );
};

export default withTranslation()(TimeoutQA);
