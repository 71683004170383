import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import EastIcon from "@mui/icons-material/East";

let mainColor = "";
let contrastColor = "";
let icon;
let increase = "notApplicable";
let increaseValue;

const calculateDisplayProps = (increase, theme) => {
    switch (increase) {
        case "increase":
            mainColor = theme.palette.success.light;
            contrastColor = theme.palette.success.contrast;
            icon = <NorthEastIcon fontSize="inherit" data-testid="northEastIcon" />;
            break;
        case "decrease":
            mainColor = theme.palette.error.main;
            contrastColor = theme.palette.error.contrast;
            icon = <SouthEastIcon fontSize="inherit" data-testid="southEastIcon" />;
            break;
        case "notApplicable":
            mainColor = theme.palette.brand.lightPurple;
            contrastColor = theme.palette.brand.darkPurple1;
            increaseValue = "N/A";
            break;
        default:
            mainColor = theme.palette.brand.lightPurple;
            contrastColor = theme.palette.brand.darkPurple1;
            icon = <EastIcon fontSize="inherit" data-testid="eastIcon" />;
            break;
    }
}
const TendencyDisplay = (props) => {
    const theme = useTheme();
    const showMovement = props.showMovement !== false;
    increase = "notApplicable";
    increaseValue = Math.round((100 * props.score) / props.previousEntry) - 100;

    if (showMovement) {
        if (props.previousEntry > 0 && props.score > 0) {
            const decrease = props.score < props.previousEntry ? "decrease" : "same";
            increase = props.score > props.previousEntry ? "increase" : decrease;
        }

        if (increaseValue > 0) {
            increaseValue = "+" + increaseValue;
        }
        increaseValue += "%";

        calculateDisplayProps(increase, theme);

        if (!props.highlightIncrease) {
            mainColor = theme.palette.brand.lightPurple;
            contrastColor = theme.palette.brand.darkPurple1;
        }
    }

    return (
        <>
            {showMovement && (
                <div data-testid="tendency_container" className="tendency_container" style={{ backgroundColor: mainColor, color: contrastColor }}>
                    {icon || null}
                    <Typography
                        tabIndex={0}
                        className="increase_container"
                        fontFamily="'Open Sans', sans-serif, Arial"
                        fontSize="inherit"
                        sx={{ marginLeft: "4.5px" }}
                        lineHeight={1}
                        color="inherit"
                        gutterBottom={false}
                        aria-label={increase + (increaseValue != "N/A" ? increaseValue : "")}
                    >
                        {increaseValue}
                    </Typography>
                </div>
            )}
        </>
    );
};

export default TendencyDisplay;
