import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import i18n from "../../internationalization/i18n";
import { HighContrastBlackTheme } from "./themes/HighContrastBlackTheme";
import { HighContrastWhiteTheme } from "./themes/HighContrastWhiteTheme";

const AngularGaugeWithTwoAxes = ({ data, chartId, colorThemeMui }) => {
    if (!data || data.length === 0 || !chartId) {
        return { root: undefined, chart: undefined };
    }

    const highZoneColor = am5.color("#6736F4");
    const lowZoneColor = am5.color("#CCBDF6");
    const midZoneColor = am5.color("#9A7CEF");

    let color = [am5.color("#393939"), am5.color("#686868")];

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new(chartId);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/

    const themes = [am5themes_Animated.new(root)];

    switch (colorThemeMui) {
        case "high-contrast-black":
            themes.push(HighContrastBlackTheme.new());
            color = [am5.color("#F5F5F5"), am5.color("#FFD700")];
            break;
        case "high-contrast-white":
            themes.push(HighContrastWhiteTheme.new());
            break;
    }

    root.setThemes(themes);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    let chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            startAngle: 180,
            endAngle: 360,
            radius: am5.percent(75),
            layout: root.verticalLayout,
            paddingBottom: 0,
            paddingLeft: 0,
        })
    );

    chart.zoomOutButton.setAll({
        forceHidden: true,
        ariaHidden: true,
        focusable: false,
    });

    root.numberFormatter.set("numberFormat", "#'%'");

    //Tooltip
    let tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
    });

    tooltip.label.setAll({
        fill: am5.color("#2E2F33"),
    });
    tooltip.get("background").setAll({
        fill: am5.color("#FBFBFD"),
        fillOpacity: 1,
        stroke: am5.color("#686868"),
        strokeWidth: 1,
    });

    let axisRenderer = [];
    let xAxis = [];
    let axisDataItem = [];
    let clockHand = [];

    data.forEach((el, idx) => {
        axisRenderer[idx] = am5radar.AxisRendererCircular.new(root, {
            stroke: am5.color("#ff0000"),
            innerRadius: -150,
            radius: am5.percent(100),
            strokeOpacity: 0,
            strokeWidth: 15,
            minGridDistance: 50,
        });
        axisRenderer[idx].grid.template.setAll({
            stroke: am5.color("#ffffff"),
            strokeOpacity: 1,
            strokeWidth: 1,
            layer: 0,
        });
        axisRenderer[idx].ticks.template.set("visible", false);
        axisRenderer[idx].labels.template.setAll({
            // ? textType: "adjusted", to adjust axis percent text, but crashes legend click
            radius: 5,
            paddingBottom: 10,
            forceHidden: idx !== 0,
        });

        xAxis[idx] = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                max: 100,
                strictMinMax: true,
                renderer: axisRenderer[idx],
                numberFormat: "#'%'",
            })
        );

        // Add clock hand
        axisDataItem[idx] = xAxis[idx].makeDataItem({
            value: 0,
            fill: color[idx],
            name: `${el.name} ${el.value && typeof el.value === "number" ? el.value + "%" : "N/A"}`,
        });
        clockHand[idx] = am5radar.ClockHand.new(root, {
            pinRadius: 4,
            radius: am5.percent(90),
            bottomWidth: 8,
        });
        clockHand[idx].pin.setAll({
            fill: color[idx],
        });
        clockHand[idx].hand.setAll({
            fill: color[idx],
        });

        axisDataItem[idx].set(
            "bullet",
            am5xy.AxisBullet.new(root, {
                sprite: clockHand[idx],
            })
        );

        xAxis[idx].createAxisRange(axisDataItem[idx]);

        axisDataItem[idx].get("grid").set("forceHidden", true);
        axisDataItem[idx].get("tick").set("forceHidden", true);

        if (idx === 0) {
            function createRange(start, end, color, tooltip, innerRadius) {
                let rangeDataItem = xAxis[idx].makeDataItem({
                    value: start,
                    endValue: end,
                });

                xAxis[idx].createAxisRange(rangeDataItem);
                rangeDataItem.get("tick").setAll({
                    visible: false,
                });

                rangeDataItem.get("axisFill").setAll({
                    visible: true,
                    fill: color,
                    fillOpacity: 0.8,
                    innerRadius: innerRadius,
                    radius: 200 + innerRadius,
                    tooltipText: tooltip,
                });
            }

            createRange(0, 60, lowZoneColor, i18n.t("Low"), -10);
            createRange(60, 80, midZoneColor, "", -10);
            createRange(80, 100, highZoneColor, i18n.t("High"), -10);

            createRange(0, 60, lowZoneColor, i18n.t("Low"), 0);
            createRange(60, 80, midZoneColor, "", 0);
            createRange(80, 100, highZoneColor, i18n.t("High"), 0);
        }
    });

    // Legend
    let legend = chart.bottomAxesContainer.children.push(
        am5.Legend.new(root, {
            x: am5.p50,
            centerX: am5.p50,
        })
    );

    chart.onPrivate("width", function (width) {
        legend.labels.template.setAll({
            maxWidth: width - 100,
            oversizedBehavior: "wrap",
        });
    });

    legend.data.setAll(axisDataItem);

    // Animate values
    setTimeout(() => {
        data.forEach((el, idx) => {
            if (el.value) {
                axisDataItem[idx].animate({
                    key: "value",
                    to: el.value,
                    duration: 1000,
                    easing: am5.ease.out(am5.ease.cubic),
                });
            }
        });
    }, 500);

    // Make stuff animate on load
    chart.appear(1000, 100);

    return { root, chart };
};
export default AngularGaugeWithTwoAxes;
